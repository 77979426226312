import type {
  FetchClassAlbumPayload,
  FetchClassAlbumPhotosPayload,
  FetchClassAlbumPhotosResponseParams,
  FetchClassAlbumResponseParams,
  FetchClassAlbumsPayload,
  FetchClassAlbumsResponseParams,
} from "@/utils/apis/classAlbum/classAlbum.api.types";

// Action Types

export enum ClassAlbumActionTypes {
  FETCH_REQUESTED = "@@classAlbum/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@classAlbum/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@classAlbum/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@classAlbum/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@classAlbum/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@classAlbum/MAP_FETCH_FAILED",

  // Saga
  FETCH_CLASS_ALBUMS_SAGA = "@@classAlbum/FETCH_CLASS_ALBUMS_SAGA",
  FETCH_CLASS_ALBUM_SAGA = "@@classAlbum/FETCH_CLASS_ALBUM_SAGA",
  FETCH_CLASS_ALBUM_PHOTOS_SAGA = "@@classAlbum/FETCH_CLASS_ALBUM_PHOTOS_SAGA",
}

// State

export interface ClassAlbumState {
  classAlbums: FetchClassAlbumsResponseParams["items"];
  classAlbumsLoading: boolean;
  classAlbumsError: string;
  classAlbumsCount: number;

  idToClassAlbumMap: {
    [id: string | number]: FetchClassAlbumResponseParams;
  };
  idToClassAlbumLoadingMap: {
    [id: string | number]: boolean;
  };
  idToClassAlbumErrorMap: {
    [id: string | number]: string;
  };

  classAlbumPhotos: FetchClassAlbumPhotosResponseParams["items"];
  classAlbumPhotosLoading: boolean;
  classAlbumPhotosError: string;
  classAlbumPhotosCount: number;
}

// ---- Reducer ----

type FetchScope = "classAlbums" | "classAlbumPhotos";
type MapFetchScope = "idToClassAlbum";

export type FetchRequestedAction = {
  type: ClassAlbumActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: ClassAlbumActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ClassAlbumState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ClassAlbumActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: ClassAlbumActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ClassAlbumActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: ClassAlbumState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ClassAlbumActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchClassAlbumsSagaAction = {
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUMS_SAGA;
  payload: FetchClassAlbumsPayload;
};

export type FetchClassAlbumSagaAction = {
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUM_SAGA;
  payload: FetchClassAlbumPayload;
};

export type FetchClassAlbumPhotosSagaAction = {
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUM_PHOTOS_SAGA;
  payload: FetchClassAlbumPhotosPayload;
};

export type ClassAlbumAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchClassAlbumsSagaAction
  | FetchClassAlbumSagaAction
  | FetchClassAlbumPhotosSagaAction;
