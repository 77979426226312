import { ClassLessonScheduleActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchClassLessonScheduleSagaAction,
  FetchClassLessonScheduleEndDateSagaAction,
  FetchClassLessonScheduleStartDateSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchClassLessonScheduleSaga = (
  payload: FetchClassLessonScheduleSagaAction["payload"],
  meta?: FetchClassLessonScheduleSagaAction["meta"]
): FetchClassLessonScheduleSagaAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_SAGA,
  payload,
  meta,
});

export const fetchClassLessonScheduleStartDateSaga = (
  payload: FetchClassLessonScheduleStartDateSagaAction["payload"],
  meta?: FetchClassLessonScheduleStartDateSagaAction["meta"]
): FetchClassLessonScheduleStartDateSagaAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_START_DATE_SAGA,
  payload,
  meta,
});

export const fetchClassLessonScheduleEndDateSaga = (
  payload: FetchClassLessonScheduleEndDateSagaAction["payload"],
  meta?: FetchClassLessonScheduleEndDateSagaAction["meta"]
): FetchClassLessonScheduleEndDateSagaAction => ({
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_END_DATE_SAGA,
  payload,
  meta,
});
