import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchProgramsPayload,
  FetchProgramPayload,
  FetchDiscoveryProgramsPayload,
} from "./program.api.types";

const programApi = {
  fetchPrograms: (payload: FetchProgramsPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "programs/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchProgram: (payload: FetchProgramPayload) => {
    return commonAxios.get<AxiosResponseData>("programs/getProgramById.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
  fetchDiscoveryPrograms: (payload: FetchDiscoveryProgramsPayload) => {
    return commonAxios.get<AxiosResponseData>("programs/discover.json", {
      cancelToken: payload.cancelToken,
    });
  },
};

export default programApi;
