import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { jwtService } from "@/services";
import { authApi } from "@/utils/apis";

import {
  AuthActionTypes,
  FetchUserProfileSagaAction,
  SaveUserProfileSagaAction,
  SignInSagaAction,
} from "./types";
import {
  signInSucceeded,
  checkAuthRequested,
  checkAuthFailed,
  checkAuthSucceeded,
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  fetchUserProfileSaga,
} from "./action";

function* signIn(action: SignInSagaAction) {
  const { params } = action.payload;
  const { resolve } = action.meta || {};

  try {
    const { data: response } = yield call(authApi.signIn, {
      params: params,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(signInSucceeded(response.params));
    }
    resolve && resolve(response);
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    resolve && resolve({ message });
  }
}

function* checkAuth() {
  const token = jwtService.getToken();
  document.cookie = `token=${token ?? ""};path=/`;

  if (!token) {
    yield put(checkAuthSucceeded(null));
    return;
  }

  yield put(checkAuthRequested());

  try {
    const { data: response } = yield call(authApi.checkAuth);
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(checkAuthSucceeded(response.params));
    } else {
      document.cookie = `token=;path=/`;
      yield put(checkAuthFailed({ message: response.message }));
    }
  } catch (e: any) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    document.cookie = `token=;path=/`;
    yield put(
      checkAuthFailed({ message, isNetworkError: e.code === "ERR_NETWORK" })
    );
  }
}

function* fetchUserProfile(action: FetchUserProfileSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "user";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof authApi.fetchUserProfile>> = yield call(
      authApi.fetchUserProfile,
      {
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* saveUserProfile(action: SaveUserProfileSagaAction) {
  const { params, cancelToken } = action.payload;

  const { resolve = () => {} } = action?.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof authApi.saveUserProfile>> = yield call(
      authApi.saveUserProfile,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "user",
          data: response.params,
        })
      );
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    resolve({ message });
  }
}

function* authSaga() {
  yield all([
    takeLatest(AuthActionTypes.SIGN_IN_SAGA, signIn),
    takeLatest(AuthActionTypes.CHECK_AUTH_SAGA, checkAuth),
    takeLatest(AuthActionTypes.FETCH_USER_PROFILE_SAGA, fetchUserProfile),
    takeLatest(AuthActionTypes.SAVE_USER_PROFILE_SAGA, saveUserProfile),
  ]);
}

export default authSaga;
