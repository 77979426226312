import type {
  FetchAboutUsPayload,
  FetchAboutUsResponseParams,
  FetchContactsPayload,
  FetchContactsResponseParams,
  FetchPrivacyPolicyPayload,
  FetchPrivacyPolicyResponseParams,
} from "@/utils/apis/generalInfo/generalInfo.api.types";

// Action Types

export enum GeneralInfoActionTypes {
  FETCH_REQUESTED = "@@generalInfo/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@generalInfo/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@generalInfo/FETCH_FAILED",

  // Saga
  FETCH_CONTACTS_SAGA = "@@generalInfo/FETCH_CONTACTS_SAGA",
  FETCH_ABOUT_US_SAGA = "@@generalInfo/FETCH_ABOUT_US_SAGA",
  FETCH_PRIVACY_POLICY_SAGA = "@@generalInfo/FETCH_PRIVACY_POLICY_SAGA",
}

// State

export interface GeneralInfoState {
  contacts: FetchContactsResponseParams;
  contactsLoading: boolean;
  contactsError: string;

  privacyPolicy: FetchPrivacyPolicyResponseParams | null;
  privacyPolicyLoading: boolean;
  privacyPolicyError: string;

  aboutUs: FetchAboutUsResponseParams | null;
  aboutUsLoading: boolean;
  aboutUsError: string;
}

// ---- Reducer ----

export type FetchScope = "contacts" | "privacyPolicy" | "aboutUs";

export type FetchRequestedAction = {
  type: GeneralInfoActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: GeneralInfoActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: GeneralInfoState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: GeneralInfoActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga ----

export type FetchContactsSagaAction = {
  type: GeneralInfoActionTypes.FETCH_CONTACTS_SAGA;
  payload?: FetchContactsPayload;
};

export type FetchPrivacyPolicyAction = {
  type: GeneralInfoActionTypes.FETCH_PRIVACY_POLICY_SAGA;
  payload?: FetchPrivacyPolicyPayload;
};

export type FetchAboutUsAction = {
  type: GeneralInfoActionTypes.FETCH_ABOUT_US_SAGA;
  payload?: FetchAboutUsPayload;
};

export type GeneralInfoAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchContactsSagaAction
  | FetchPrivacyPolicyAction
  | FetchAboutUsAction;
