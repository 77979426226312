import { all, call, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { classApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
  mapFetchFailed,
  updateStaffClassKidAttendanceSucceeded,
} from "./action";
import {
  ClassActionTypes,
  ClassState,
  FetchKidClassSagaAction,
  FetchStaffClassesSagaAction,
  FetchStaffClassSagaAction,
  UpdateStaffClassKidAttendanceSagaAction,
} from "./types";

import type { FetchClassesSagaAction } from "./types";
import type { AppState } from "../rootReducer";
import type { FetchClassesResponseParams } from "@/utils/apis/class/class.api.types";

function* fetchClasses(action: FetchClassesSagaAction) {
  const { params, cancelToken } = action.payload;
  const { enableLoadMore = false, isReset = false } = action.meta || {};

  const scope = "classes";

  yield put(
    fetchRequested({
      scope,
      ...(isReset
        ? {
            data: [],
          }
        : {}),
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classApi.fetchClasses>> = yield call(
      classApi.fetchClasses,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      if (enableLoadMore) {
        const classes: ClassState["classes"] = yield select(
          (state: AppState) => state.class.classes
        );
        (response.params.items as FetchClassesResponseParams["items"]).forEach(
          (_class) => {
            const existedClass = classes.some((c) => c.id === _class.id);
            !existedClass && classes.push(_class);
          }
        );
        yield put(
          fetchSucceeded({
            scope,
            data: classes,
            count: response.params.count,
          })
        );
      } else
        yield put(
          fetchSucceeded({
            scope,
            data: response.params.items,
            count: response.params.count,
          })
        );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchStaffClasses(action: FetchStaffClassesSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const scope = "staffClasses";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classApi.fetchStaffClasses>> = yield call(
      classApi.fetchStaffClasses,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchStaffClass(action: FetchStaffClassSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.cidc_class_id;
  const scope = "idToStaffClass";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classApi.fetchStaffClass>> = yield call(
      classApi.fetchStaffClass,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* fetchKidClass(action: FetchKidClassSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.cidc_class_id;
  const scope = "idToKidClass";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classApi.fetchKidClass>> = yield call(
      classApi.fetchKidClass,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* updateStaffClassKidAttendance(
  action: UpdateStaffClassKidAttendanceSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta;

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classApi.updateStaffClassKidAttendance>> =
      yield call(classApi.updateStaffClassKidAttendance, {
        params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(updateStaffClassKidAttendanceSucceeded(params));
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    resolve({ message });
  }
}

function* classSaga() {
  yield all([
    takeEvery(ClassActionTypes.FETCH_CLASSES_SAGA, fetchClasses),
    takeEvery(ClassActionTypes.FETCH_STAFF_CLASSES_SAGA, fetchStaffClasses),
    takeEvery(ClassActionTypes.FETCH_STAFF_CLASS_SAGA, fetchStaffClass),
    takeEvery(ClassActionTypes.FETCH_KID_CLASS_SAGA, fetchKidClass),
    takeEvery(
      ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SAGA,
      updateStaffClassKidAttendance
    ),
  ]);
}

export default classSaga;
