import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { commonApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import { CommonActionTypes } from "./types";

import type { FetchRegisterTermsAndConditionsContentSagaAction } from "./types";

function* fetchRegisterTermsAndConditionsContentSaga(
  action: FetchRegisterTermsAndConditionsContentSagaAction
) {
  const { cancelToken } = action.payload || {};

  const scope = "registerTermsAndConditionsContent";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof commonApi.fetchRegisterTermsAndConditionsContent>
    > = yield call(commonApi.fetchRegisterTermsAndConditionsContent, {
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return;
    }
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* commonSaga() {
  yield all([
    takeEvery(
      CommonActionTypes.FETCH_REGISTER_TERMS_AND_CONDITIONS_CONTENT_SAGA,
      fetchRegisterTermsAndConditionsContentSaga
    ),
  ]);
}

export default commonSaga;
