import axios from "axios";

import { classRegistrationOrderApi } from "@/utils/apis";
import { axiosHelpers } from "@/utils/helpers";

import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { useMemo } from "react";

import type {
  FetchFullClassRegistrationOrderPayload,
  FetchFullClassRegistrationOrderResponseParams,
} from "@/utils/apis/classRegistrationOrder/classRegistrationOrder.api.types";

type QueryFullClassRegistrationOrderOptions = {
  key?: any[];
};

const useQueryFullClassRegistrationOrder = (
  options?: QueryFullClassRegistrationOrderOptions
) => {
  const { key: controlledKey } = options || {};

  const queryClient = useQueryClient();

  const key = useMemo(() => {
    return ["fullClassRegistrationOrder"].concat(
      controlledKey ? [...controlledKey] : []
    );
  }, [controlledKey]);

  const query = useQuery({
    enabled: false,
    queryKey: key,
  });

  const mutation = useMutation({
    mutationKey: key,
    mutationFn: async (payload: FetchFullClassRegistrationOrderPayload) => {
      const { data } =
        await classRegistrationOrderApi.fetchFullClassRegistrationOrder(
          payload
        );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(key, data);
    },
    onError: (error) => {
      const message = axios.isAxiosError(error)
        ? (error.response?.data as any)?.message || error.message
        : "";
      queryClient.setQueryData(key, {
        message,
      });
    },
  });

  const isMutating = useIsMutating({
    mutationKey: key,
  });

  const loading = useMemo(() => {
    return isMutating > 0;
  }, [isMutating]);

  const error = useMemo(() => {
    return !loading && !axiosHelpers.checkRequestSuccess(query.data)
      ? (query.data as any)?.message
      : "";
  }, [loading, query.data]);

  const fetch = (payload: FetchFullClassRegistrationOrderPayload) => {
    mutation.mutate(payload);
  };

  return {
    data:
      (query.data as FetchFullClassRegistrationOrderResponseParams)?.params ||
      null,
    loading: isMutating > 0,
    error: error,
    fetch: fetch,
  };
};

export default useQueryFullClassRegistrationOrder;
