import moment from "moment";

import { commonConfig } from "@/utils/config";

import { useTranslation } from "next-i18next";

const useAppMomentWithLocale = () => {
  const { i18n } = useTranslation();
  const momentWithLocale = moment;
  momentWithLocale.locale(i18n.language);

  const momentWithLocaleByTz = (value: string, format: string) => {
    return momentWithLocale(
      `${value}${commonConfig.DEFAULT_GMT}`,
      `${format}Z`
    );
  };

  return {
    momentWithLocale,
    momentWithLocaleByTz,
  };
};

export default useAppMomentWithLocale;
