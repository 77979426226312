import { CourseActionTypes, FetchAgeRangeCoursesSagaAction } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchCoursesSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CourseActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CourseActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CourseActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchCoursesSaga = (
  payload?: FetchCoursesSagaAction["payload"]
): FetchCoursesSagaAction => ({
  type: CourseActionTypes.FETCH_COURSES_SAGA,
  payload,
});

export const fetchAgeRangeCoursesSaga = (
  payload?: FetchAgeRangeCoursesSagaAction["payload"]
): FetchAgeRangeCoursesSagaAction => ({
  type: CourseActionTypes.FETCH_AGE_RANGE_COURSES_SAGA,
  payload,
});
