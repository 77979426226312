import { ClassActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  ResetModuleStateAction,
  UpdateStaffClassKidAttendanceSucceededAction,
  // Saga
  FetchClassesSagaAction,
  FetchStaffClassesSagaAction,
  FetchStaffClassSagaAction,
  FetchKidClassSagaAction,
  UpdateStaffClassKidAttendanceSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ClassActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ClassActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ClassActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: ClassActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: ClassActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: ClassActionTypes.MAP_FETCH_FAILED,
  payload,
});

export const resetModuleState = (
  payload: ResetModuleStateAction["payload"]
): ResetModuleStateAction => ({
  type: ClassActionTypes.RESET_MODULE_STATE,
  payload,
});

export const updateStaffClassKidAttendanceSucceeded = (
  payload: UpdateStaffClassKidAttendanceSucceededAction["payload"]
): UpdateStaffClassKidAttendanceSucceededAction => ({
  type: ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SUCCEEDED,
  payload,
});

// ---- SAGA ----

export const fetchClassesSaga = (
  payload: FetchClassesSagaAction["payload"],
  meta?: FetchClassesSagaAction["meta"]
): FetchClassesSagaAction => ({
  type: ClassActionTypes.FETCH_CLASSES_SAGA,
  payload,
  meta,
});

export const fetchStaffClassesSaga = (
  payload: FetchStaffClassesSagaAction["payload"],
  meta?: FetchStaffClassesSagaAction["meta"]
): FetchStaffClassesSagaAction => ({
  type: ClassActionTypes.FETCH_STAFF_CLASSES_SAGA,
  payload,
  meta,
});

export const fetchStaffClassSaga = (
  payload: FetchStaffClassSagaAction["payload"],
  meta?: FetchStaffClassSagaAction["meta"]
): FetchStaffClassSagaAction => ({
  type: ClassActionTypes.FETCH_STAFF_CLASS_SAGA,
  payload,
  meta,
});

export const fetchKidClassSaga = (
  payload: FetchKidClassSagaAction["payload"],
  meta?: FetchKidClassSagaAction["meta"]
): FetchKidClassSagaAction => ({
  type: ClassActionTypes.FETCH_KID_CLASS_SAGA,
  payload,
  meta,
});

export const updateStaffClassKidAttendanceSagaAction = (
  payload: UpdateStaffClassKidAttendanceSagaAction["payload"],
  meta: UpdateStaffClassKidAttendanceSagaAction["meta"]
): UpdateStaffClassKidAttendanceSagaAction => ({
  type: ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SAGA,
  payload,
  meta,
});
