import { authV3Axios } from "@/utils/axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchClassLessonSchedulePayload,
  FetchClassLessonScheduleStartDatePayload,
  FetchClassLessonScheduleEndDatePayload,
} from "./classLessonSchedule.api.types";

const classLessonScheduleApi = {
  fetchClassLessonSchedule: (payload: FetchClassLessonSchedulePayload) => {
    return authV3Axios.get<AxiosResponseData>(
      "CidcClasses/getLessonScheduleAndFees.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchClassLessonScheduleStartDate: (
    payload: FetchClassLessonScheduleStartDatePayload
  ) => {
    return authV3Axios.get<AxiosResponseData>(
      "CidcClasses/getStartDateTriggerNumberOfLessons.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchClassLessonScheduleEndDate: (
    payload: FetchClassLessonScheduleEndDatePayload
  ) => {
    return authV3Axios.get<AxiosResponseData>(
      "CidcClasses/getEndDateTriggerNumberOfLessonsStartDate.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default classLessonScheduleApi;
