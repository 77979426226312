import { CommonActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchRegisterTermsAndConditionsContentSagaAction,
} from "./types";

// ---- REDUCER ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CommonActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CommonActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CommonActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchRegisterTermsAndConditionsContentSaga = (
  payload?: FetchRegisterTermsAndConditionsContentSagaAction["payload"]
): FetchRegisterTermsAndConditionsContentSagaAction => ({
  type: CommonActionTypes.FETCH_REGISTER_TERMS_AND_CONDITIONS_CONTENT_SAGA,
  payload,
});
