import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { newApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
} from "./action";
import { NewActionTypes } from "./types";

import type { FetchNewsSagaAction, FetchNewSagaAction } from "./types";

function* fetchNews(action: FetchNewsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { scope: controlledScope } = action?.meta || {};

  const scope = controlledScope ?? "news";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const { data: response }: Awaited<ReturnType<typeof newApi.fetchNews>> =
      yield call(newApi.fetchNews, {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.total,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchNew(action: FetchNewSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.id;
  const scope = "idToNew";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const { data: response }: Awaited<ReturnType<typeof newApi.fetchNew>> =
      yield call(newApi.fetchNew, {
        params: params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* newSaga() {
  yield all([
    takeEvery(NewActionTypes.FETCH_NEWS_SAGA, fetchNews),
    takeEvery(NewActionTypes.FETCH_NEW_SAGA, fetchNew),
  ]);
}

export default newSaga;
