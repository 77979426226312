import nextI18nextConfig from "@@/next-i18next.config";

export const DOCUMENT_TITLE = "CIDC Kids";
export const APP_VERSION = "0.1.54";
export const APP_NAME = "app";
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST!;
export const WEBSOCKET_ENDPOINT = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT!;
export const DEFAULT_LOCALE = nextI18nextConfig.i18n.defaultLocale;
export const DEFAULT_GMT = "+08:00";
export const DEFAULT_PHONE_COUNTRY_CODE = "+852";
export const INSTAGRAM_PAGE_LINK =
  "https://instagram.com/cidckids?igshid=MzRlODBiNWFlZA==";
export const FACEBOOK_PAGE_LINK = "https://www.facebook.com/cidc.hk";
