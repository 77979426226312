import type {
  FetchCentresPayload,
  FetchCentresResponseParams,
} from "@/utils/apis/centre/centre.api.types";

// Action Types

export enum CentreActionTypes {
  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  // Saga
  FETCH_CENTRES_SAGA = "@@branch/FETCH_CENTRES_SAGA",
}

// State

export interface CentreState {
  centres: FetchCentresResponseParams["items"];
  centresLoading: boolean;
  centresError: string;
  centresCount: number;
}

// ---- Reducer ----

export type FetchScope = "centres";

export type FetchRequestedAction = {
  type: CentreActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: CentreActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CentreState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: CentreActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga ----

export type FetchCentresSagaAction = {
  type: CentreActionTypes.FETCH_CENTRES_SAGA;
  payload?: FetchCentresPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CentreAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchCentresSagaAction;
