import { authAxios } from "@/utils/axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchKidsPayload,
  FetchKidPayload,
  FetchStaffKidsPayload,
  FetchStaffKidPayload,
  FetchClassesKidsPayload,
  UpdateKidPayload,
  CreateKidPayload,
} from "./kid.api.types";

const kidApi = {
  fetchKids: (payload: FetchKidsPayload) => {
    return authAxios.get<AxiosResponseData>("kids/getListPagination.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  fetchKid: (payload: FetchKidPayload) => {
    return authAxios.get<AxiosResponseData>("kids/getDetail.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
  fetchClassesKids: (payload: FetchClassesKidsPayload) => {
    return authAxios.get<AxiosResponseData>(
      "cidcParents/webGetClassesByToken.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchStaffKids: (payload: FetchStaffKidsPayload) => {
    return authAxios.get<AxiosResponseData>(
      "Kids/staffGetListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchStaffKid: (payload: FetchStaffKidPayload) => {
    return authAxios.get<AxiosResponseData>("kids/staffGetDetail.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },

  updateKid: (payload: UpdateKidPayload) => {
    return authAxios.post<AxiosResponseData>("kids/edit.json", payload.params, {
      cancelToken: payload.cancelToken,
    });
  },
  createKid: (payload: CreateKidPayload) => {
    return authAxios.post<AxiosResponseData>("kids/add.json", payload.params, {
      cancelToken: payload.cancelToken,
    });
  },
};

export default kidApi;
