import { combineReducers } from "redux";

import auth from "./auth/reducer";
import feedback from "./feedback/reducer";
import program from "./program/reducer";
import course from "./course/reducer";
import classType from "./classType/reducer";
import _new from "./new/reducer";
import generalInfo from "./generalInfo/reducer";
import centre from "./centre/reducer";
import _class from "./class/reducer";
import classRegistrationOrder from "./classRegistrationOrder/reducer";
import kid from "./kid/reducer";
import classAlbum from "./classAlbum/reducer";
import professional from "./professional/reducer";
import notification from "./notification/reducer";
import classLessonSchedule from "./classLessonSchedule/reducer";
import common from "./common/reducer";

const rootReducer = combineReducers({
  auth,
  feedback,
  program,
  course,
  classType,
  generalInfo,
  centre,
  classRegistrationOrder,
  kid,
  classAlbum,
  professional,
  notification,
  classLessonSchedule,
  class: _class,
  new: _new,
  common,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
