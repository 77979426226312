import _sumBy from "lodash/sumBy";

import { commonHelpers } from "@/utils/helpers";

import { ClassRegistrationOrderActionTypes } from "./types";
import { storageService } from "@/services";

import type {
  ClassRegistrationOrderState,
  ClassRegistrationOrderAction,
} from "./types";

const draftClassRegistrationOrder = storageService.getLocalItem<
  ClassRegistrationOrderState["draftClassRegistrationOrder"]
>("draftClassRegistrationOrder");

const calculateDraftClassRegistrationOrder = (
  draftClassRegistrationOrder: ClassRegistrationOrderState["draftClassRegistrationOrder"]
) => {
  let newDraftClassRegistrationOrder = { ...draftClassRegistrationOrder };
  newDraftClassRegistrationOrder.total_fee_decimal = 0;
  newDraftClassRegistrationOrder.total_quantity = 0;
  newDraftClassRegistrationOrder.total_selected_quantity = 0;
  newDraftClassRegistrationOrder = newDraftClassRegistrationOrder.items.reduce(
    (_newDraftClassRegistrationOrder, item) => {
      _newDraftClassRegistrationOrder.total_fee_decimal =
        (_sumBy(
          item.classes.filter(
            (_class) => _class.selected && !_class.error_message
          ),
          "fee_decimal"
        ) ?? 0) + _newDraftClassRegistrationOrder.total_fee_decimal;
      _newDraftClassRegistrationOrder.total_quantity =
        _newDraftClassRegistrationOrder.total_quantity + item.classes.length;
      _newDraftClassRegistrationOrder.total_selected_quantity =
        _newDraftClassRegistrationOrder.total_selected_quantity +
        item.classes.filter(
          (_class) => _class.selected && !_class.error_message
        ).length;
      return _newDraftClassRegistrationOrder;
    },
    newDraftClassRegistrationOrder
  );
  return newDraftClassRegistrationOrder;
};

export const initialState: ClassRegistrationOrderState = {
  idToClassRegistrationOrderMap: {},
  idToClassRegistrationOrderLoadingMap: {},
  idToClassRegistrationOrderErrorMap: {},

  draftClassRegistrationOrder: draftClassRegistrationOrder ?? {
    items: [],
    error_message: "",
    total_fee_decimal: 0,
    total_selected_quantity: 0,
    total_quantity: 0,
  },
  draftClassRegistrationOrderError: "",
  draftClassRegistrationOrderLoading: false,
};

const reducer = (
  state = initialState,
  action: ClassRegistrationOrderAction
) => {
  switch (action.type) {
    case ClassRegistrationOrderActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };
    }
    case ClassRegistrationOrderActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case ClassRegistrationOrderActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }

    case ClassRegistrationOrderActionTypes.MAP_FETCH_REQUESTED: {
      const { scope, key } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: true,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: "",
      };
      Object.entries(loadingMap).length >= 100 &&
        delete loadingMap[Object.entries(loadingMap)[0]?.[0]];
      Object.entries(errorMap).length >= 100 &&
        delete errorMap[Object.entries(errorMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    case ClassRegistrationOrderActionTypes.MAP_FETCH_SUCCEEDED: {
      const { scope, key, data } = action.payload;
      const dataMap = {
        ...state[`${scope}Map`],
        [key]: data,
      };
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      Object.entries(dataMap).length >= 100 &&
        delete dataMap[Object.entries(dataMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}Map`]: dataMap,
        [`${scope}LoadingMap`]: loadingMap,
      };
    }
    case ClassRegistrationOrderActionTypes.MAP_FETCH_FAILED: {
      const { scope, key, error } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: error,
      };

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }

    case ClassRegistrationOrderActionTypes.ADD_DRAFT_CLASS_REGISTRATION_ORDER: {
      const { kid, class: _class } = action.payload;

      let newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
      };
      let draftOrderItems = [...newDraftClassRegistrationOrder.items];

      const draftOrderItemByKidIndex = draftOrderItems.findIndex(
        (draftOrderItem) => {
          return draftOrderItem.kid.id === kid.id;
        }
      );

      if (draftOrderItemByKidIndex > -1) {
        const draftOrderItemClasses =
          draftOrderItems[draftOrderItemByKidIndex].classes;
        const draftOrderItemClassIndex = draftOrderItemClasses.findIndex(
          (draftOrderItemClass) => {
            return draftOrderItemClass.id === _class.id;
          }
        );
        if (draftOrderItemClassIndex === -1) {
          draftOrderItemClasses.unshift(_class);
        } else {
          console.log(_class, "h4ello");
          draftOrderItemClasses[draftOrderItemClassIndex] = {
            ...draftOrderItemClasses[draftOrderItemClassIndex],
            ..._class,
          };
        }
      } else {
        draftOrderItems.unshift({
          kid,
          classes: [_class],
        });
      }

      if (_class.selected) {
        draftOrderItems = draftOrderItems
          .sort((prevItem) => (prevItem.kid.id === kid.id ? -1 : 0))
          .map((draftOrderItem) => {
            const draftOrderItemClasses = [...draftOrderItem.classes].sort(
              (prevItem) => (prevItem.id === _class.id ? -1 : 0)
            );

            draftOrderItem.classes = draftOrderItemClasses.map(
              (draftOrderItemClass) => {
                draftOrderItemClass.selected =
                  draftOrderItemClass.id === _class.id ? 1 : 0;
                return { ...draftOrderItemClass };
              }
            );
            return { ...draftOrderItem };
          });
      }
      newDraftClassRegistrationOrder.items = draftOrderItems;

      newDraftClassRegistrationOrder = calculateDraftClassRegistrationOrder(
        newDraftClassRegistrationOrder
      );

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.REMOVE_DRAFT_CLASS_REGISTRATION_ORDER: {
      const removedDraftClassRegistrationOrders = Array.isArray(action.payload)
        ? action.payload
        : action.payload
        ? [action.payload]
        : [];

      let newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
      };

      newDraftClassRegistrationOrder.items =
        removedDraftClassRegistrationOrders.length < 1
          ? []
          : newDraftClassRegistrationOrder.items
              .map((newDraftClassRegistrationOrderItem) => {
                const removedDraftClassRegistrationOrderIndex =
                  removedDraftClassRegistrationOrders.findIndex(
                    (removedDraftClassRegistrationOrder) => {
                      return (
                        newDraftClassRegistrationOrderItem.kid.id ===
                        removedDraftClassRegistrationOrder.kidId
                      );
                    }
                  );
                if (removedDraftClassRegistrationOrderIndex > -1) {
                  const removedDraftClassRegistrationOrder =
                    removedDraftClassRegistrationOrders[
                      removedDraftClassRegistrationOrderIndex
                    ];
                  newDraftClassRegistrationOrderItem.classes =
                    !removedDraftClassRegistrationOrder.classId
                      ? []
                      : newDraftClassRegistrationOrderItem.classes.filter(
                          (classRegistrationOrderClass) => {
                            return (
                              removedDraftClassRegistrationOrder.classId !==
                              classRegistrationOrderClass.id
                            );
                          }
                        );
                }
                return newDraftClassRegistrationOrderItem;
              })
              .filter((newDraftClassRegistrationOrderItem) => {
                return newDraftClassRegistrationOrderItem.classes.length > 0;
              });

      newDraftClassRegistrationOrder = calculateDraftClassRegistrationOrder(
        newDraftClassRegistrationOrder
      );

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.REMOVE_SELECTED_DRAFT_CLASS_REGISTRATION_ORDER_CLASS: {
      let newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
      };

      newDraftClassRegistrationOrder.items =
        newDraftClassRegistrationOrder.items
          .map((newDraftClassRegistrationOrderItem) => {
            newDraftClassRegistrationOrderItem.classes =
              newDraftClassRegistrationOrderItem.classes.filter(
                (classRegistrationOrderClass) => {
                  return !classRegistrationOrderClass.selected;
                }
              );
            return newDraftClassRegistrationOrderItem;
          })
          .filter((newDraftClassRegistrationOrderItem) => {
            return newDraftClassRegistrationOrderItem.classes.length > 0;
          });

      newDraftClassRegistrationOrder = calculateDraftClassRegistrationOrder(
        newDraftClassRegistrationOrder
      );

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.SELECT_DRAFT_CLASS_REGISTRATION_ORDER: {
      const selectedDraftClassRegistrationOrderItem = action.payload;

      let newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
      };

      const checkedAllKid = newDraftClassRegistrationOrder.items.every(
        (item) => {
          return item.classes
            .filter((_class) => !_class.error_message)
            .every((_class) => _class.selected);
        }
      );

      newDraftClassRegistrationOrder.items =
        newDraftClassRegistrationOrder.items.map(
          (newDraftClassRegistrationOrderItem) => {
            if (!selectedDraftClassRegistrationOrderItem) {
              newDraftClassRegistrationOrderItem.classes =
                newDraftClassRegistrationOrderItem.classes.map((_class) => {
                  _class.selected = checkedAllKid ? 0 : 1;
                  return _class;
                });
            } else if (
              !selectedDraftClassRegistrationOrderItem.classId &&
              selectedDraftClassRegistrationOrderItem.kidId ===
                newDraftClassRegistrationOrderItem.kid.id
            ) {
              const checkedAllKidClass =
                newDraftClassRegistrationOrderItem.classes
                  .filter((_class) => !_class.error_message)
                  .every((_class) => _class.selected);
              newDraftClassRegistrationOrderItem.classes =
                newDraftClassRegistrationOrderItem.classes.map((_class) => {
                  _class.selected = checkedAllKidClass ? 0 : 1;
                  return _class;
                });
            } else if (
              selectedDraftClassRegistrationOrderItem.kidId ===
              newDraftClassRegistrationOrderItem.kid.id
            ) {
              newDraftClassRegistrationOrderItem.classes =
                newDraftClassRegistrationOrderItem.classes.map((_class) => {
                  if (
                    _class.id ===
                    selectedDraftClassRegistrationOrderItem.classId
                  )
                    _class.selected = _class.selected ? 0 : 1;
                  return _class;
                });
            }
            return newDraftClassRegistrationOrderItem;
          }
        );

      newDraftClassRegistrationOrder = calculateDraftClassRegistrationOrder(
        newDraftClassRegistrationOrder
      );

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED: {
      let newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
      };

      newDraftClassRegistrationOrder.items =
        newDraftClassRegistrationOrder.items
          .map((newDraftClassRegistrationOrderItem) => {
            newDraftClassRegistrationOrderItem.classes =
              newDraftClassRegistrationOrderItem.classes.filter((_class) => {
                return !_class.selected;
              });
            return newDraftClassRegistrationOrderItem;
          })
          .filter((newDraftClassRegistrationOrderItem) => {
            return newDraftClassRegistrationOrderItem.classes.length > 0;
          });

      newDraftClassRegistrationOrder = calculateDraftClassRegistrationOrder(
        newDraftClassRegistrationOrder
      );

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_FAILED: {
      const newDraftClassRegistrationOrder = {
        ...state.draftClassRegistrationOrder,
        ...action.payload,
      };

      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );

      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    case ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED: {
      const newDraftClassRegistrationOrder = action.payload;
      storageService.saveLocalItem(
        "draftClassRegistrationOrder",
        newDraftClassRegistrationOrder
      );
      return {
        ...state,
        draftClassRegistrationOrder: newDraftClassRegistrationOrder,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
