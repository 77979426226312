import { authAxios, authV2Axios, commonAxios } from "@/utils/axios";

import type {
  SignInPayload,
  SignUpPayload,
  ResetPasswordPayload,
  SendOtpSmsPayload,
  CheckPasswordResetOtpPayload,
  CheckPasswordResetTokenPayload,
  SaveUserProfilePayload,
  ChangePasswordPayload,
  FetchUserProfilePayload,
  ValidateEmailPhonePayload,
} from "./auth.api.types";
import type { AxiosResponseData } from "@/utils/axios";

const authApi = {
  signIn: (payload: SignInPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "users/login.json",
      payload.params
    );
  },
  signUp: (payload: SignUpPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "CidcParents/register.json",
      payload.params
    );
  },
  checkAuth: () => {
    return authAxios.get<AxiosResponseData>("users/getProfile.json");
  },
  fetchUserProfile: (payload: FetchUserProfilePayload) => {
    return authAxios.get<AxiosResponseData>("users/getProfile.json", {
      cancelToken: payload.cancelToken,
    });
  },
  saveUserProfile: (payload: SaveUserProfilePayload) => {
    return authAxios.post<AxiosResponseData>(
      "CidcParents/editParent.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  changePassword: (payload: ChangePasswordPayload) => {
    return authAxios.post<AxiosResponseData>(
      "users/changePassword.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  sendOtpSms: (payload: SendOtpSmsPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "users/sendSmsVerificationCode.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  checkPasswordResetOtp: (payload: CheckPasswordResetOtpPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "users/checkSmsForgotCode.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  checkPasswordResetToken: (payload: CheckPasswordResetTokenPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "users/checkTokenReset.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  resetPassword: (payload: ResetPasswordPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "users/resetPassword.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  validateEmailPhone: (payload: ValidateEmailPhonePayload) => {
    return authV2Axios.post<AxiosResponseData>(
      "users/checkEmailPhoneNumber.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default authApi;
