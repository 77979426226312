import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import generalStyles from "./general.styles";
import overridesStyles from "./overrides.styles";
import customStyles from "./custom.styles";

const muiTheme = createTheme();

const fontFamily = "var(--font-nunito), sans-serif";

const typography: TypographyOptions = {
  fontFamily: fontFamily,
  fontWeightBlack: 900,
  fontWeightExtraBold: 800,
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  body1: undefined,
  body2: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  caption: undefined,
  overline: undefined,
  nunitoBlackAA62: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(62),
    fontWeight: 900,
    lineHeight: "120%",
    textTransform: "uppercase",
  },
  nunitoBlackAA48: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(48),
    fontWeight: 900,
    lineHeight: "120%",
    textTransform: "uppercase",
  },
  nunitoExtraBold38: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(38),
    fontWeight: 800,
    lineHeight: "120%",
  },
  nunitoExtraBold28: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(28),
    fontWeight: 800,
    lineHeight: "120%",
  },
  nunitoExtraBold22: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(22),
    fontWeight: 800,
    lineHeight: "120%",
  },
  nunitoExtraBoldAA16: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(16),
    fontWeight: 800,
    lineHeight: "120%",
    textTransform: "uppercase",
  },
  nunitoExtraBoldAA13: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(13),
    fontWeight: 800,
    lineHeight: muiTheme.typography.pxToRem(18),
    textTransform: "uppercase",
  },
  nunitoExtraBoldAA12: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(12),
    fontWeight: 800,
    lineHeight: muiTheme.typography.pxToRem(16),
    textTransform: "uppercase",
  },
  nunitoBold18: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: "120%",
  },
  nunitoBold16: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: "130%",
  },
  nunitoSemiBold16: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: "140%",
  },
  nunitoSemiBold13: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: "123%",
  },
  nunitoReg16: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: "160%",
    letterSpacing: "-0.01em",
  },
  nunitoReg13: {
    fontFamily: fontFamily,
    fontSize: muiTheme.typography.pxToRem(13),
    fontWeight: 400,
    lineHeight: "123%",
  },
};

typography.button = {
  ...typography.nunitoBold16,
  textTransform: "initial",
};

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1536,
      lg: 1200,
      md: 900,
      tablet: 730,
      sm: 600,
      xs: 0,
    },
  },
  palette: {
    primary: {
      main: "#7ECDEC",
      contrastText: "#FFFFFF",
    },
    common: {
      bg: "#F3F7FB",
      black: "#062942",
      blackGrey: "#807F7D",
      grey: "#A1B0BB",
      brown: "#782D27",
      lightGreen: "#E7F16A",
      lightPink: "#ECA6C8",
      blue: "#7ECDEC",
      orange: "#EAB162",
      green: "#8BBF53",
      purple: "#AD83C3",
      lightRed: "#FED9DA",
      darkPink: "#EB9BC2",
      darkGreen: "#D7E05F",
      darkBlue: "#66B4D2",
      darkBg: "#DAE7EF",
      gradient1:
        "linear-gradient(90deg, #FED9DA 24.05%, rgba(254, 217, 218, 0.6) 63.04%, rgba(254, 217, 218, 0) 95.79%)",
    },
    text: {
      primary: "#062942",
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParams: any) => `
				${generalStyles(themeParams)}
				${overridesStyles(themeParams)}
        ${customStyles(themeParams)}
			`,
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1350,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#DAE7EF",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "textPrimary",
        variant: "nunitoReg16",
        variantMapping: {
          nunitoBlackAA62: "p",
          nunitoBlackAA48: "p",
          nunitoExtraBold38: "p",
          nunitoExtraBold28: "p",
          nunitoExtraBold22: "p",
          nunitoExtraBoldAA16: "p",
          nunitoExtraBoldAA13: "p",
          nunitoExtraBoldAA12: "p",
          nunitoBold18: "p",
          nunitoBold16: "p",
          nunitoSemiBold16: "p",
          nunitoSemiBold13: "p",
          nunitoReg16: "p",
          nunitoReg13: "p",
        },
      },
    },
  },
  typography: typography,
  zIndex: {
    backdrop: 1350,
  },
  app: {
    shadows: [
      "0px 4px 35px rgba(69, 96, 112, 0.1)",
      "0px 7px 15px rgba(69, 96, 112, 0.22)",
      "0px 7px 20px #7ECDEC",
    ],
  },
});

export default defaultTheme;
