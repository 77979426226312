import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { classLessonScheduleApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import {
  ClassLessonScheduleActionTypes,
  FetchClassLessonScheduleEndDateSagaAction,
  FetchClassLessonScheduleStartDateSagaAction,
} from "./types";

import type { FetchClassLessonScheduleSagaAction } from "./types";

function* fetchClassLessonSchedule(action: FetchClassLessonScheduleSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const scope = "classLessonSchedule";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof classLessonScheduleApi.fetchClassLessonSchedule>
    > = yield call(classLessonScheduleApi.fetchClassLessonSchedule, {
      params,
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchClassLessonScheduleStartDate(
  action: FetchClassLessonScheduleStartDateSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const scope = "classLessonScheduleStartDate";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof classLessonScheduleApi.fetchClassLessonScheduleStartDate
      >
    > = yield call(classLessonScheduleApi.fetchClassLessonScheduleStartDate, {
      params,
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchClassLessonScheduleEndDate(
  action: FetchClassLessonScheduleEndDateSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const scope = "classLessonScheduleEndDate";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof classLessonScheduleApi.fetchClassLessonScheduleEndDate>
    > = yield call(classLessonScheduleApi.fetchClassLessonScheduleEndDate, {
      params,
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* classLessonScheduleSaga() {
  yield all([
    takeEvery(
      ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_SAGA,
      fetchClassLessonSchedule
    ),
    takeEvery(
      ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_START_DATE_SAGA,
      fetchClassLessonScheduleStartDate
    ),
    takeEvery(
      ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_END_DATE_SAGA,
      fetchClassLessonScheduleEndDate
    ),
  ]);
}

export default classLessonScheduleSaga;
