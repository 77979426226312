import {
  FetchFeedbacksPayload,
  FetchFeedbacksResponseParams,
} from "@/utils/apis/feedback/feedback.api.types";

// Action Types

export enum FeedbackActionTypes {
  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  // Saga
  FETCH_FEEDBACKS_SAGA = "@@branch/FETCH_FEEDBACKS_SAGA",
}

// State

export interface FeedbackState {
  feedbacks: FetchFeedbacksResponseParams["items"];
  feedbacksLoading: boolean;
  feedbacksError: string;
  feedbacksCount: number;
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: FeedbackActionTypes.FETCH_REQUESTED;
  payload: {
    scope: "feedbacks";
  };
};

export type FetchSucceededAction = {
  type: FeedbackActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: "feedbacks";
    data: FeedbackState[FetchSucceededAction["payload"]["scope"]];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: FeedbackActionTypes.FETCH_FAILED;
  payload: {
    scope: "feedbacks";
    error: string;
  };
};

// ---- Saga ----

export type FetchFeedbacksSagaAction = {
  type: FeedbackActionTypes.FETCH_FEEDBACKS_SAGA;
  payload?: FetchFeedbacksPayload;
};

export type FeedbackAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchFeedbacksSagaAction;
