import {
  FetchKidsPayload,
  FetchKidPayload,
  FetchKidsResponseParams,
  FetchKidResponseParams,
  FetchStaffKidsResponseParams,
  FetchStaffKidsPayload,
  FetchStaffKidResponseParams,
  FetchStaffKidPayload,
  FetchClassesKidsResponseParams,
  FetchClassesKidsPayload,
} from "@/utils/apis/kid/kid.api.types";

// Action Types

export enum KidActionTypes {
  FETCH_REQUESTED = "@@kid/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@kid/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@kid/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@kid/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@kid/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@kid/MAP_FETCH_FAILED",

  // Saga
  FETCH_KIDS_SAGA = "@@kid/FETCH_KIDS_SAGA",
  FETCH_KID_SAGA = "@@kid/FETCH_KID_SAGA",
  FETCH_STAFF_KIDS_SAGA = "@@kid/FETCH_STAFF_KIDS_SAGA",
  FETCH_STAFF_KID_SAGA = "@@kid/FETCH_STAFF_KID_SAGA",
  FETCH_CLASSES_KIDS_SAGA = "@@kid/FETCH_CLASSES_KIDS_SAGA",
}

// State

export interface KidState {
  kids: FetchKidsResponseParams["items"];
  kidsLoading: boolean;
  kidsError: string;
  kidsCount: number;

  idToKidMap: {
    [id: string | number]: FetchKidResponseParams;
  };
  idToKidLoadingMap: {
    [id: string | number]: boolean;
  };
  idToKidErrorMap: {
    [id: string | number]: string;
  };

  staffKids: FetchStaffKidsResponseParams["items"];
  staffKidsLoading: boolean;
  staffKidsError: string;
  staffKidsCount: number;

  idToStaffKidMap: {
    [id: string | number]: FetchStaffKidResponseParams;
  };
  idToStaffKidLoadingMap: {
    [id: string | number]: boolean;
  };
  idToStaffKidErrorMap: {
    [id: string | number]: string;
  };

  classesKids: FetchClassesKidsResponseParams["items"];
  classesKidsLoading: boolean;
  classesKidsError: string;
  classesKidsCount: number;
}

// ---- Reducer ----

type FetchScope = "kids" | "staffKids" | "classesKids";
type MapFetchScope = "idToKid" | "idToStaffKid";

export type FetchRequestedAction = {
  type: KidActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: KidActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: KidState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: KidActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: KidActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: KidActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: KidState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: KidActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchKidsSagaAction = {
  type: KidActionTypes.FETCH_KIDS_SAGA;
  payload: FetchKidsPayload;
  meta?: {
    enableLoadMore?: boolean;
    isReset?: boolean;
  };
};

export type FetchKidSagaAction = {
  type: KidActionTypes.FETCH_KID_SAGA;
  payload: FetchKidPayload;
};

export type FetchStaffKidsSagaAction = {
  type: KidActionTypes.FETCH_STAFF_KIDS_SAGA;
  payload: FetchStaffKidsPayload;
};

export type FetchStaffKidSagaAction = {
  type: KidActionTypes.FETCH_STAFF_KID_SAGA;
  payload: FetchStaffKidPayload;
};

export type FetchClassesKidsSagaAction = {
  type: KidActionTypes.FETCH_CLASSES_KIDS_SAGA;
  payload: FetchClassesKidsPayload;
};

export type KidAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchKidsSagaAction
  | FetchKidSagaAction
  | FetchStaffKidsSagaAction
  | FetchStaffKidSagaAction;
