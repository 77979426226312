import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { AuthAction } from "./auth/types";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export type { AppState } from "./rootReducer";
export type AppDispatch = typeof store.dispatch;
export type AppAction = AuthAction;

export default store;

export * as storeAuthAction from "./auth/action";
export * as storeFeedbackAction from "./feedback/action";
export * as storeProgramAction from "./program/action";
export * as storeCourseAction from "./course/action";
export * as storeClassTypeAction from "./classType/action";
export * as storeGeneralInfoAction from "./generalInfo/action";
export * as storeNewAction from "./new/action";
export * as storeCentreAction from "./centre/action";
export * as storeClassAction from "./class/action";
export * as storeKidAction from "./kid/action";
export * as storeClassAlbumAction from "./classAlbum/action";
export * as storeClassRegistrationOrderAction from "./classRegistrationOrder/action";
export * as storeProfessionalAction from "./professional/action";
export * as storeNotificationAction from "./notification/action";
export * as storeClassLessonScheduleAction from "./classLessonSchedule/action";
export * as storeCommonAction from "./common/action";
