import { ClassLessonScheduleActionTypes } from "./types";

import type {
  ClassLessonScheduleState,
  ClassLessonScheduleAction,
} from "./types";

export const initialState: ClassLessonScheduleState = {
  classLessonSchedule: null,
  classLessonScheduleLoading: false,
  classLessonScheduleError: "",

  classLessonScheduleStartDate: null,
  classLessonScheduleStartDateLoading: false,
  classLessonScheduleStartDateError: "",

  classLessonScheduleEndDate: null,
  classLessonScheduleEndDateLoading: false,
  classLessonScheduleEndDateError: "",
};

const reducer = (state = initialState, action: ClassLessonScheduleAction) => {
  switch (action.type) {
    case ClassLessonScheduleActionTypes.FETCH_REQUESTED: {
      const { scope, data } = action.payload;

      const moduleState = {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };

      typeof data !== "undefined" &&
        Object.assign(moduleState, {
          [scope]: data,
        });

      return {
        ...state,
        ...moduleState,
      };
    }
    case ClassLessonScheduleActionTypes.FETCH_SUCCEEDED: {
      const { scope, data } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
      };
    }
    case ClassLessonScheduleActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
