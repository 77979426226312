import { authAxios, authV3Axios } from "@/utils/axios";

import type {
  UploadFilesPayload,
  SendBroadcastMessagePayload,
  FetchRegisterTermsAndConditionsContentPayload,
  FetchRegisterTermsAndConditionsContentResponseData,
} from "./common.api.types";
import type { AxiosResponseData } from "@/utils/axios";

const commonApi = {
  uploadFiles: (payload: UploadFilesPayload) => {
    const fd = new FormData();
    Object.entries(payload.params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => fd.append(`${key}[]`, v as any));
      } else fd.append(key, value as any);
    });
    return authAxios.post<AxiosResponseData>("files/upload.json", fd, {
      cancelToken: payload.cancelToken,
    });
  },
  sendBroadcastMessage: (payload: SendBroadcastMessagePayload) => {
    return authV3Axios.post<AxiosResponseData>(
      "SystemMessages/sendMessage.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchRegisterTermsAndConditionsContent: (
    payload?: FetchRegisterTermsAndConditionsContentPayload
  ) => {
    return authAxios.get<
      AxiosResponseData<FetchRegisterTermsAndConditionsContentResponseData>
    >("terms/getDetailRegister.json", {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default commonApi;
