import { ClassTypeActionTypes, FetchClassTypeSagaAction } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  // Saga
  FetchClassTypesSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ClassTypeActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ClassTypeActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ClassTypeActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: ClassTypeActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: ClassTypeActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: ClassTypeActionTypes.MAP_FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchClassTypeSaga = (
  payload: FetchClassTypeSagaAction["payload"],
  meta?: FetchClassTypeSagaAction["meta"]
): FetchClassTypeSagaAction => {
  return {
    type: ClassTypeActionTypes.FETCH_CLASS_TYPE_SAGA,
    payload,
    meta,
  };
};

export const fetchClassTypesSaga = (
  payload?: FetchClassTypesSagaAction["payload"],
  meta?: FetchClassTypesSagaAction["meta"]
): FetchClassTypesSagaAction => {
  return {
    type: ClassTypeActionTypes.FETCH_CLASS_TYPES_SAGA,
    payload,
    meta,
  };
};
