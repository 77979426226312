import { makeStyles } from "tss-react/mui";

type Params = {
  width?: number | `${number}`;
  height?: number | `${number}`;
  objectFit?: string;
  objectPosition?: string;
};

const useStyles = makeStyles<Params>({
  name: "appImageV2",
})((theme, params) => {
  return {
    root: {
      position: "relative",
      width: params?.width,
      height: params?.height,
    },
    img: {
      objectFit: params.objectFit || ("cover" as any),
      objectPosition: params.objectPosition || "top center",
    },
    errorImg: {
      objectFit: "contain" as any,
      objectPosition: "center",
      objectSize: "",
      backgroundColor: theme.palette.grey[100],
    },
  };
});

export default useStyles;
