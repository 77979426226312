import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { classTypeApi } from "@/utils/apis";

import {
  mapFetchRequested,
  mapFetchSucceeded,
  mapFetchFailed,
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
} from "./action";
import { ClassTypeActionTypes, FetchClassTypeSagaAction } from "./types";

import type { FetchClassTypesSagaAction } from "./types";

function* fetchClassType(action: FetchClassTypeSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.class_type_id;
  const scope = "typeIdToClassType";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classTypeApi.fetchClassType>> = yield call(
      classTypeApi.fetchClassType,
      {
        params: params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* fetchClassTypes(action: FetchClassTypesSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "classTypes";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classTypeApi.fetchClassTypes>> = yield call(
      classTypeApi.fetchClassTypes,
      {
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* classTypeSaga() {
  yield all([
    takeEvery(ClassTypeActionTypes.FETCH_CLASS_TYPES_SAGA, fetchClassTypes),
    takeEvery(ClassTypeActionTypes.FETCH_CLASS_TYPE_SAGA, fetchClassType),
  ]);
}

export default classTypeSaga;
