import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchProfessionalsPayload,
  FetchProfessionalPayload,
} from "./professional.api.types";

const professionalApi = {
  fetchProfessionals: (payload: FetchProfessionalsPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "professionals/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchProfessional: (payload: FetchProfessionalPayload) => {
    return commonAxios.get<AxiosResponseData>("professionals/getById.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
};

export default professionalApi;
