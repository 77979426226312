import { io, Socket } from "socket.io-client";
import { bindActionCreators } from "redux";

import { storeNotificationAction } from "@/store";
import { commonConfig } from "@/utils/config";

import SocketIOContext from "./SocketIO.context";

import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useAppSocketIO } from "@/hooks";

import type { SocketIOContextProps } from "./SocketIO.context";

type SocketIOProviderProps = {
  children: React.ReactNode;
};

const SocketIOInitialization = () => {
  const dispatch = useAppDispatch();

  const $s_hasAuth = useAppSelector((state) => !!state.auth.user?.id);
  const $s_authChecking = useAppSelector(
    (state) => state.auth.userAuthChecking
  );

  const $s_notificationAction = useMemo(
    () => bindActionCreators(storeNotificationAction, dispatch),
    [dispatch]
  );

  const handleSocketConnect = () => {
    !$s_authChecking && $s_hasAuth && methods.updateNotificationCount();
  };

  const { socket, methods } = useAppSocketIO({
    onConnect: handleSocketConnect,
    onNotificationUpdate: (data) => {
      $s_notificationAction.updateUnreadNotificationCountByAuthUserSaga({
        unread_notification_count: data?.result?.numberMessages,
        user_id: data?.result?.parentId,
      });
    },
  });

  useEffect(() => {
    if (!$s_authChecking && $s_hasAuth) {
      socket.connected && methods.updateNotificationCount();
    }
  }, [$s_authChecking, $s_hasAuth]);

  return null;
};

const SocketIOProvider = (props: SocketIOProviderProps) => {
  const { children } = props;

  const socketRef = useRef<Socket | null>(null);

  const closeSocketIO = () => {
    socketRef.current && socketRef.current.close();
  };

  const connectSocketIO = () => {
    if (typeof window === "undefined") return;
    socketRef.current = io(commonConfig.WEBSOCKET_ENDPOINT, {
      reconnectionDelayMax: 1000 * 10,
      reconnectionDelay: 1000 * 5,
      reconnectionAttempts: Infinity,
      reconnection: true,
    }).connect();
  };

  if (!socketRef.current) {
    connectSocketIO();
  }

  useEffect(() => {
    return () => {
      // closeSocketIO();
    };
  }, []);

  const value = useMemo<SocketIOContextProps>(() => {
    return {
      socket: socketRef.current!,
    };
  }, []);

  return (
    <SocketIOContext.Provider value={value!}>
      <SocketIOInitialization />
      {children}
    </SocketIOContext.Provider>
  );
};

export default SocketIOProvider;
