import { useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";

import { useSocketIOContext } from "@/contexts/SocketIO";
import { getToken } from "@/services/jwt/jwt.service";
import { storeNotificationAction } from "@/store";

import useAppDispatch from "./useAppDispatch";
import useEventCallback from "./useEventCallback";

type NotificationUpdate = (data: {
  result: {
    parentId: number;
    numberMessages: number;
  };
}) => void;

type AppSocketIOProps = {
  onConnect?: () => void;
  onNotificationUpdate?: NotificationUpdate;
};

const useAppSocketIO = (props?: AppSocketIOProps) => {
  const { onConnect = () => {}, onNotificationUpdate = () => {} } = props || {};

  const executeConnect = useEventCallback(onConnect);
  const executeNotificationUpdate = useEventCallback(onNotificationUpdate);

  const dispatch = useAppDispatch();

  const $s_notificationAction = useMemo(
    () => bindActionCreators(storeNotificationAction, dispatch),
    [dispatch]
  );

  const { socket } = useSocketIOContext();

  const updateNotificationCount = () => {
    const token = getToken();
    socket.emit(
      "getNotification",
      {
        token,
      },
      (data: { result: { numberMessages: number; parentId: number } }) => {
        const userId = data.result.parentId;
        const unreadNotificationCount = data.result.numberMessages;

        $s_notificationAction.updateUnreadNotificationCountByAuthUserSaga({
          user_id: userId,
          unread_notification_count: unreadNotificationCount,
        });
      }
    );
  };

  const readNotification = async (params: { id: number }) => {
    $s_notificationAction.fetchNotificationSaga({
      params: {
        id: params.id,
      },
    });
    socket.emit("viewDetailNotification", { id: params.id });
  };

  useEffect(() => {
    socket && socket.on("connect", executeConnect);
    return () => {
      socket && socket.off("connect", executeConnect);
    };
  }, []);

  useEffect(() => {
    socket && socket.on("changeStatusToAllEvent", executeNotificationUpdate);
    return () => {
      socket && socket.off("changeStatusToAllEvent", executeNotificationUpdate);
    };
  }, []);

  return {
    socket,
    methods: {
      updateNotificationCount,
      readNotification,
    },
  };
};

export default useAppSocketIO;
