import {
  createContext as createContextOriginal,
  useEffect,
  useRef,
} from "react";

import type { Context } from "react";

export const ORIGINAL_PROVIDER = Symbol();

const createProvider = <V, C extends React.ReactNode>(
  ProviderOriginal: React.ComponentType<any>
) => {
  return ({ value, children }: { value: V; children: C }) => {
    const valueRef = useRef(value);
    const listenersRef = useRef(new Set());
    const contextValue = useRef({
      value: valueRef,
      registerListener: (listener: React.ForwardedRef<any>) => {
        listenersRef.current.add(listener);
        return () => listenersRef.current.delete(listener);
      },
      listeners: new Set(),
    });

    useEffect(() => {
      valueRef.current = value;
      listenersRef.current.forEach((listener: any) => {
        listener(value);
      });
    }, [value]);

    return (
      <ProviderOriginal value={contextValue.current}>
        {children}
      </ProviderOriginal>
    );
  };
};

const createContextApp = <T,>(defaultValue: T): Context<T> => {
  const context = createContextOriginal({
    value: {
      current: defaultValue,
    },
    register: () => {
      return () => {};
    },
  });

  delete (context as any).Consumer;

  (context as any).Provider = createProvider(context.Provider);

  return context as unknown as Context<T>;
};

export default createContextApp;
