import type {
  FetchNotificationPayload,
  FetchNotificationResponseParams,
  FetchNotificationsPayload,
  FetchNotificationsResponseParams,
} from "@/utils/apis/notification/notification.api.types";

// Action Types

export enum NotificationActionTypes {
  FETCH_REQUESTED = "@@notification/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@notification/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@notification/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@notification/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@notification/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@notification/MAP_FETCH_FAILED",

  SET_UNREAD_NOTIFICATION_COUNT = "@@notification/SET_UNREAD_NOTIFICATION_COUNT",

  SET_NOTIFICATIONS_UNREAD_TO_READ = "@@notification/SET_NOTIFICATIONS_UNREAD_TO_READ",

  // Saga
  FETCH_NOTIFICATIONS_SAGA = "@@notification/FETCH_NOTIFICATIONS_SAGA",
  FETCH_NOTIFICATION_SAGA = "@@notification/FETCH_NOTIFICATION_SAGA",
  UPDATE_UNREAD_NOTIFICATION_COUNT_BY_AUTH_USER_SAGA = "@@notification/UPDATE_UNREAD_NOTIFICATION_COUNT_BY_AUTH_USER_SAGA",
}

// State

export interface NotificationState {
  notifications: FetchNotificationsResponseParams["items"];
  notificationsLoading: boolean;
  notificationsError: string;
  notificationsCount: number;

  idToNotificationMap: {
    [id: number | string]: FetchNotificationResponseParams;
  };
  idToNotificationErrorMap: {
    [id: number | string]: boolean;
  };
  idToNotificationLoadingMap: {
    [id: number | string]: string;
  };

  unreadNotificationCount: number;
}

// ---- Reducer ----

type FetchScope = "notifications";

export type FetchRequestedAction = {
  type: NotificationActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};
export type FetchSucceededAction = {
  type: NotificationActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: NotificationState[FetchSucceededAction["payload"]["scope"]];
    count?: number;
  };
};
export type FetchFailedAction = {
  type: NotificationActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: NotificationActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: "idToNotification";
    key: string | number;
  };
};
export type MapFetchSucceededAction = {
  type: NotificationActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: "idToNotification";
    key: string | number;
    data: NotificationState[`${MapFetchSucceededAction["payload"]["scope"]}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};
export type MapFetchFailedAction = {
  type: NotificationActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: "idToNotification";
    key: string | number;
    error: string;
  };
};

export type SetUnreadNotificationCountAction = {
  type: NotificationActionTypes.SET_UNREAD_NOTIFICATION_COUNT;
  payload: number;
};

export type SetNotificationsUnreadToReadAction = {
  type: NotificationActionTypes.SET_NOTIFICATIONS_UNREAD_TO_READ;
  payload: number;
};

// ---- Saga ----

export type FetchNotificationsSagaAction = {
  type: NotificationActionTypes.FETCH_NOTIFICATIONS_SAGA;
  payload: FetchNotificationsPayload;
  meta?: {
    disableLoading?: boolean;
  };
};

export type FetchNotificationSagaAction = {
  type: NotificationActionTypes.FETCH_NOTIFICATION_SAGA;
  payload: FetchNotificationPayload;
};

export type UpdateUnreadNotificationCountByAuthUserSagaAction = {
  type: NotificationActionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT_BY_AUTH_USER_SAGA;
  payload: {
    user_id: number;
    unread_notification_count: number;
  };
};

export type NotificationAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  | SetUnreadNotificationCountAction
  | SetNotificationsUnreadToReadAction
  //
  | FetchNotificationsSagaAction
  | UpdateUnreadNotificationCountByAuthUserSagaAction;
