import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { courseApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import { CourseActionTypes, FetchAgeRangeCoursesSagaAction } from "./types";

import type { FetchCoursesSagaAction } from "./types";

function* fetchCourses(action: FetchCoursesSagaAction) {
  const { params, cancelToken } = action.payload || {};

  yield put(
    fetchRequested({
      scope: "courses",
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof courseApi.fetchCourses>> = yield call(
      courseApi.fetchCourses,
      {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "courses",
          data: response.params.items,
          count: response.params.total,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope: "courses",
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope: "courses",
        error: message,
      })
    );
  }
}

function* fetchAgeRangeCourses(action: FetchAgeRangeCoursesSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "ageRangeCourses" as Parameters<
    typeof fetchRequested
  >[0]["scope"];

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof courseApi.fetchAgeRangeCourses>> = yield call(
      courseApi.fetchAgeRangeCourses,
      {
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* courseSaga() {
  yield all([
    takeEvery(CourseActionTypes.FETCH_COURSES_SAGA, fetchCourses),
    takeEvery(
      CourseActionTypes.FETCH_AGE_RANGE_COURSES_SAGA,
      fetchAgeRangeCourses
    ),
  ]);
}

export default courseSaga;
