import { KidActionTypes } from "./types";

import { commonHelpers } from "@/utils/helpers";

import type { KidState, KidAction } from "./types";

export const initialState: KidState = {
  kids: [],
  kidsError: "",
  kidsLoading: false,
  kidsCount: 0,

  idToKidMap: {},
  idToKidErrorMap: {},
  idToKidLoadingMap: {},

  staffKids: [],
  staffKidsLoading: false,
  staffKidsError: "",
  staffKidsCount: 0,

  idToStaffKidErrorMap: {},
  idToStaffKidLoadingMap: {},
  idToStaffKidMap: {},

  classesKids: [],
  classesKidsCount: 0,
  classesKidsError: "",
  classesKidsLoading: false,
};

const reducer = (state = initialState, action: KidAction) => {
  switch (action.type) {
    case KidActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };
    }
    case KidActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }

    case KidActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }

    case KidActionTypes.MAP_FETCH_REQUESTED: {
      const { scope, key } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: true,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: "",
      };
      Object.entries(loadingMap).length >= 100 &&
        delete loadingMap[Object.entries(loadingMap)[0]?.[0]];
      Object.entries(errorMap).length >= 100 &&
        delete errorMap[Object.entries(errorMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    case KidActionTypes.MAP_FETCH_SUCCEEDED: {
      const { scope, key, data } = action.payload;
      const dataMap = {
        ...state[`${scope}Map`],
        [key]: data,
      };
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      Object.entries(dataMap).length >= 100 &&
        delete dataMap[Object.entries(dataMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}Map`]: dataMap,
        [`${scope}LoadingMap`]: loadingMap,
      };
    }

    case KidActionTypes.MAP_FETCH_FAILED: {
      const { scope, key, error } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: error,
      };

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
