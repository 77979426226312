import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchClassTypePayload,
  FetchClassTypesPayload,
} from "./classType.api.types";

const classTypesApi = {
  fetchClassTypes: (payload: FetchClassTypesPayload) => {
    return commonAxios.get<AxiosResponseData>("ClassTypes/getList.json", {
      cancelToken: payload.cancelToken,
    });
  },
  fetchClassType: (payload: FetchClassTypePayload) => {
    return commonAxios.get<AxiosResponseData>(
      "cidcClasses/getClassesByClassTypes.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default classTypesApi;
