import { Theme } from "@mui/material/styles";

const styleOverrides = (theme: Theme) => {
  return `
		.Toastify__toast-container {
			z-index: ${theme.zIndex.snackbar};
			font-family: ${theme.typography.nunitoReg16.fontFamily};
			font-size: ${theme.typography.nunitoReg16.fontSize};
			line-height: ${theme.typography.nunitoReg16.lineHeight};
			font-weight: ${theme.typography.nunitoReg16.fontWeight};
			color: ${theme.palette.text.primary};
		}
	`;
};

export default styleOverrides;
