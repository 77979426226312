import type {
  FetchClassTypePayload,
  FetchClassTypeResponseParams,
  FetchClassTypesPayload,
  FetchClassTypesResponseParams,
} from "@/utils/apis/classType";

// Action Types

export enum ClassTypeActionTypes {
  FETCH_REQUESTED = "@@classType/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@classType/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@classType/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@classType/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@classType/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@classType/MAP_FETCH_FAILED",

  // Saga
  FETCH_CLASS_TYPES_SAGA = "@@classType/FETCH_CLASS_TYPES_SAGA",
  FETCH_CLASS_TYPE_SAGA = "@@classType/FETCH_CLASS_TYPE_SAGA",
}

// State

export interface ClassTypeState {
  classTypes: FetchClassTypesResponseParams;
  classTypesLoading: boolean;
  classTypesError: string;

  typeIdToClassTypeMap: {
    [typeId: string | number]: FetchClassTypeResponseParams;
  };
  typeIdToClassTypeLoadingMap: {
    [typeId: string | number]: boolean;
  };
  typeIdToClassTypeErrorMap: {
    [typeId: string | number]: string;
  };
}

// ---- Reducer ----

export type FetchScope = "classTypes";

export type FetchRequestedAction = {
  type: ClassTypeActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: ClassTypeActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ClassTypeState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ClassTypeActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchScope = "typeIdToClassType";

export type MapFetchRequestedAction = {
  type: ClassTypeActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ClassTypeActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: ClassTypeState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ClassTypeActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchClassTypeSagaAction = {
  type: ClassTypeActionTypes.FETCH_CLASS_TYPE_SAGA;
  payload: FetchClassTypePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchClassTypesSagaAction = {
  type: ClassTypeActionTypes.FETCH_CLASS_TYPES_SAGA;
  payload?: FetchClassTypesPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type ClassTypeAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchClassTypeSagaAction
  | FetchClassTypesSagaAction;
