// Action Types

import {
  FetchClassRegistrationOrderPayload,
  FetchClassRegistrationOrderResponseParams,
  FetchDraftClassRegistrationOrderPayload,
  SubmitDraftClassRegistrationOrderPayload,
} from "@/utils/apis/classRegistrationOrder/classRegistrationOrder.api.types";

export enum ClassRegistrationOrderActionTypes {
  FETCH_REQUESTED = "@@classRegistrationOrder/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@classRegistrationOrder/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@classRegistrationOrder/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@classRegistrationOrder/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@classRegistrationOrder/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@classRegistrationOrder/MAP_FETCH_FAILED",

  ADD_DRAFT_CLASS_REGISTRATION_ORDER = "@@classRegistrationOrder/ADD_DRAFT_CLASS_REGISTRATION_ORDER",
  REMOVE_DRAFT_CLASS_REGISTRATION_ORDER = "@@classRegistrationOrder/REMOVE_DRAFT_CLASS_REGISTRATION_ORDER",
  REMOVE_SELECTED_DRAFT_CLASS_REGISTRATION_ORDER_CLASS = "@@classRegistrationOrder/REMOVE_SELECTED_DRAFT_CLASS_REGISTRATION_ORDER_CLASS",
  SELECT_DRAFT_CLASS_REGISTRATION_ORDER = "@@classRegistrationOrder/SELECT_DRAFT_CLASS_REGISTRATION_ORDER",
  SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED = "@@classRegistrationOrder/SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED",
  SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_FAILED = "@@classRegistrationOrder/SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_FAILED",
  FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED = "@@classRegistrationOrder/FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED",
  // Saga
  FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SAGA = "@@classRegistrationOrder/FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SAGA",
  SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SAGA = "@@classRegistrationOrder/SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SAGA",
  FETCH_CLASS_REGISTRATION_ORDER_SAGA = "@@classRegistrationOrder/FETCH_CLASS_REGISTRATION_ORDER_SAGA",
}

// State

export type DraftClassRegistrationOrderV2 = {
  items: {
    kid: {
      id: number;
      name: string;
      avatar: string;
      gender_id: number;
    };
    classes: {
      id: number;
      name: string;
      fee_decimal: number;
      start_date: string;
      end_date: string;
      start_time: string;
      end_time: string;
      number_of_lessons: EmptySafeNumber;
      center: {
        id: number;
        name: string;
      };
      class_type: {
        id: number;
        name: string;
      };
      program: {
        id: number;
        name: string;
        image: string;
      };
      course: {
        id: number;
        name: string;
        course_types: {
          content: string;
          name: string;
        };
      };
      selected: number;
      error_message: string;
    }[];
  }[];
  error_message: string;
  total_quantity: number;
  total_selected_quantity: number;
  total_fee_decimal: number;
};

export interface ClassRegistrationOrderState {
  idToClassRegistrationOrderMap: {
    [id: string]: FetchClassRegistrationOrderResponseParams;
  };
  idToClassRegistrationOrderLoadingMap: {
    [id: string]: boolean;
  };
  idToClassRegistrationOrderErrorMap: {
    [id: string]: boolean;
  };

  draftClassRegistrationOrder: DraftClassRegistrationOrderV2;
  draftClassRegistrationOrderError: string;
  draftClassRegistrationOrderLoading: boolean;
}

// ---- Reducer ----
export type FetchScope = "draftClassRegistrationOrder";

export type FetchRequestedAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ClassRegistrationOrderState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

type MapFetchScope = "idToClassRegistrationOrder";

export type MapFetchRequestedAction = {
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: ClassRegistrationOrderState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

export type SubmitDraftClassRegistrationOrderSucceededAction = {
  type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED;
};

export type SubmitDraftClassRegistrationOrderFailedAction = {
  type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_FAILED;
  payload: DraftClassRegistrationOrderV2;
};

export type AddDraftClassRegistrationOrderAction = {
  type: ClassRegistrationOrderActionTypes.ADD_DRAFT_CLASS_REGISTRATION_ORDER;
  payload: {
    kid: DraftClassRegistrationOrderV2["items"][number]["kid"];
    class: DraftClassRegistrationOrderV2["items"][number]["classes"][number];
  };
};

export type RemoveDraftClassRegistrationOrderAction = {
  type: ClassRegistrationOrderActionTypes.REMOVE_DRAFT_CLASS_REGISTRATION_ORDER;
  payload?:
    | {
        kidId: number;
        classId?: number;
      }
    | {
        kidId: number;
        classId?: number;
      }[];
};

export type SelectDraftClassRegistrationOrderAction = {
  type: ClassRegistrationOrderActionTypes.SELECT_DRAFT_CLASS_REGISTRATION_ORDER;
  payload?: {
    kidId: number;
    classId?: number;
  };
};

export type RemoveSelectedDraftClassRegistrationOrderClassAction = {
  type: ClassRegistrationOrderActionTypes.REMOVE_SELECTED_DRAFT_CLASS_REGISTRATION_ORDER_CLASS;
};

export type FetchDraftClassRegistrationOrderSucceededAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED;
  payload: DraftClassRegistrationOrderV2;
};

// ---- Saga ----

export type FetchDraftClassRegistrationOrderSagaAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SAGA;
  payload?: Pick<FetchDraftClassRegistrationOrderPayload, "cancelToken">;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchClassRegistrationOrderSagaAction = {
  type: ClassRegistrationOrderActionTypes.FETCH_CLASS_REGISTRATION_ORDER_SAGA;
  payload: FetchClassRegistrationOrderPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SubmitDraftClassRegistrationOrderSagaAction = {
  type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SAGA;
  payload: SubmitDraftClassRegistrationOrderPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type ClassRegistrationOrderAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  | RemoveDraftClassRegistrationOrderAction
  | RemoveSelectedDraftClassRegistrationOrderClassAction
  | AddDraftClassRegistrationOrderAction
  | SelectDraftClassRegistrationOrderAction
  | FetchDraftClassRegistrationOrderSucceededAction
  // saga
  | FetchDraftClassRegistrationOrderSagaAction
  | SubmitDraftClassRegistrationOrderSucceededAction
  | SubmitDraftClassRegistrationOrderFailedAction;
