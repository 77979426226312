import moment from "moment";

import { ClassActionTypes } from "./types";

import { commonHelpers } from "@/utils/helpers";
import { commonConfig } from "@/utils/config";

import type { ClassState, ClassAction } from "./types";

export const initialState: ClassState = {
  classes: [],
  classesError: "",
  classesLoading: false,
  classesCount: 0,

  staffClasses: [],
  staffClassesCount: 0,
  staffClassesError: "",
  staffClassesLoading: false,

  idToStaffClassMap: {},
  idToStaffClassErrorMap: {},
  idToStaffClassLoadingMap: {},

  idToKidClassMap: {},
  idToKidClassErrorMap: {},
  idToKidClassLoadingMap: {},
};

const reducer = (state = initialState, action: ClassAction) => {
  switch (action.type) {
    case ClassActionTypes.FETCH_REQUESTED: {
      const { scope, data } = action.payload;

      const moduleState = {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };

      typeof data !== "undefined" &&
        Object.assign(moduleState, {
          [scope]: data,
        });

      return {
        ...state,
        ...moduleState,
      };
    }
    case ClassActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case ClassActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }

    case ClassActionTypes.RESET_MODULE_STATE: {
      const scopes = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const moduleState = {};

      scopes.forEach((scope) => {
        if (!initialState[scope]) return;
        Object.assign(moduleState, {
          [`${scope}Loading`]: "",
          [`${scope}Error`]: false,
          [scope]: initialState[scope],
        });
        typeof initialState[`${scope}Count`] !== "undefined" &&
          Object.assign(moduleState, {
            [`${scope}Count`]: 0,
          });
      });
      return {
        ...state,
        ...(moduleState as ClassState),
      };
    }

    case ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SUCCEEDED: {
      const nowMoment = moment();
      const classId = action.payload.class_id;
      const attended = action.payload.attended;
      const staffClass = state.idToStaffClassMap[classId];

      if (!!staffClass) {
        const newStaffClass = { ...staffClass };
        newStaffClass.kid_info = newStaffClass.kid_info.map((kid) => {
          const attendedKid = attended.find(
            (item) => item.kid_id === kid.kid.id
          );
          if (!!attendedKid) {
            const kidAttendanceByNowIndex = kid.attendance.findIndex(
              (attendance) => {
                const attendanceDate = moment(
                  `${attendance.date}${commonConfig.DEFAULT_GMT}`,
                  "YYYY-MM-DDZ"
                );
                return nowMoment.isBetween(
                  attendanceDate,
                  moment(attendanceDate).add(1, "day")
                );
              }
            );

            if (kidAttendanceByNowIndex > -1)
              kid.attendance[kidAttendanceByNowIndex].status =
                attendedKid.status;
          }
          return kid;
        });

        return {
          ...state,
          idToStaffClassMap: {
            [classId]: newStaffClass,
          },
        };
      }
      return {
        ...state,
      };
    }

    case ClassActionTypes.MAP_FETCH_REQUESTED: {
      const { scope, key } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: true,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: "",
      };
      Object.entries(loadingMap).length >= 100 &&
        delete loadingMap[Object.entries(loadingMap)[0]?.[0]];
      Object.entries(errorMap).length >= 100 &&
        delete errorMap[Object.entries(errorMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    case ClassActionTypes.MAP_FETCH_SUCCEEDED: {
      const { scope, key, data } = action.payload;
      const dataMap = {
        ...state[`${scope}Map`],
        [key]: data,
      };
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      Object.entries(dataMap).length >= 100 &&
        delete dataMap[Object.entries(dataMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}Map`]: dataMap,
        [`${scope}LoadingMap`]: loadingMap,
      };
    }

    case ClassActionTypes.MAP_FETCH_FAILED: {
      const { scope, key, error } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: error,
      };

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
