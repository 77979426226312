import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { generalInfoApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import { GeneralInfoActionTypes } from "./types";

import type { FetchContactsSagaAction } from "./types";

function* fetchContacts(action: FetchContactsSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "contacts";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof generalInfoApi.fetchContacts>> = yield call(
      generalInfoApi.fetchContacts,
      {
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchPrivacyPolicy(action: FetchContactsSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "privacyPolicy";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof generalInfoApi.fetchPrivacyPolicy>> =
      yield call(generalInfoApi.fetchPrivacyPolicy, {
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchAboutUs(action: FetchContactsSagaAction) {
  const { cancelToken } = action.payload || {};

  const scope = "aboutUs";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof generalInfoApi.fetchAboutUs>> = yield call(
      generalInfoApi.fetchAboutUs,
      {
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* generaInfoSaga() {
  yield all([
    takeEvery(GeneralInfoActionTypes.FETCH_CONTACTS_SAGA, fetchContacts),
    takeEvery(
      GeneralInfoActionTypes.FETCH_PRIVACY_POLICY_SAGA,
      fetchPrivacyPolicy
    ),
    takeEvery(GeneralInfoActionTypes.FETCH_ABOUT_US_SAGA, fetchAboutUs),
  ]);
}

export default generaInfoSaga;
