import {
  FetchNewsPayload,
  FetchNewPayload,
  FetchNewsResponseParams,
  FetchNewResponseParams,
} from "@/utils/apis/new/new.api.types";

// Action Types

export enum NewActionTypes {
  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@branch/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@branch/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@branch/MAP_FETCH_FAILED",

  // Saga
  FETCH_NEWS_SAGA = "@@branch/FETCH_NEWS_SAGA",
  FETCH_NEW_SAGA = "@@branch/FETCH_NEW_SAGA",
}

// State

export interface NewState {
  news: FetchNewsResponseParams["items"];
  newsLoading: boolean;
  newsError: string;
  newsCount: number;

  homeNews: FetchNewsResponseParams["items"];
  homeNewsLoading: boolean;
  homeNewsError: string;
  homeNewsCount: number;

  idToNewMap: {
    [id: string | number]: FetchNewResponseParams;
  };
  idToNewLoadingMap: {
    [id: string | number]: boolean;
  };
  idToNewErrorMap: {
    [id: string | number]: string;
  };
}

// ---- Reducer ----

type FetchScope = "news" | "homeNews";

export type FetchRequestedAction = {
  type: NewActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: NewActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: NewState[FetchSucceededAction["payload"]["scope"]];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: NewActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: NewActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: "idToNew";
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: NewActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: "idToNew";
    key: string | number;
    data: NewState[`${MapFetchSucceededAction["payload"]["scope"]}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: NewActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: "idToNew";
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchNewsSagaAction = {
  type: NewActionTypes.FETCH_NEWS_SAGA;
  payload?: FetchNewsPayload;
  meta?: {
    scope?: FetchScope;
  };
};

export type FetchNewSagaAction = {
  type: NewActionTypes.FETCH_NEW_SAGA;
  payload: FetchNewPayload;
};

export type NewAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchNewsSagaAction
  | FetchNewSagaAction;
