import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { FetchFeedbacksPayload } from "./feedback.api.types";

const feedbackApi = {
  fetchFeedbacks: (payload: FetchFeedbacksPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "feedbacks/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default feedbackApi;
