import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { FetchCentresPayload } from "./centre.api.types";

const centreApi = {
  fetchCentres: (payload: FetchCentresPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "centers/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default centreApi;
