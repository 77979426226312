import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { classAlbumApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
  mapFetchFailed,
} from "./action";
import { ClassAlbumActionTypes } from "./types";

import type {
  FetchClassAlbumsSagaAction,
  FetchClassAlbumSagaAction,
  FetchClassAlbumPhotosSagaAction,
} from "./types";

function* fetchClassAlbums(action: FetchClassAlbumsSagaAction) {
  const { params, cancelToken } = action.payload || {};

  yield put(
    fetchRequested({
      scope: "classAlbums",
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classAlbumApi.fetchClassAlbums>> = yield call(
      classAlbumApi.fetchClassAlbums,
      {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "classAlbums",
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope: "classAlbums",
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope: "classAlbums",
        error: message,
      })
    );
  }
}

function* fetchClassAlbum(action: FetchClassAlbumSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.cidc_class_id;
  const scope = "idToClassAlbum";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classAlbumApi.fetchClassAlbum>> = yield call(
      classAlbumApi.fetchClassAlbum,
      {
        params: params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* fetchClassAlbumPhotos(action: FetchClassAlbumPhotosSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const scope = "classAlbumPhotos";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof classAlbumApi.fetchClassAlbumPhotos>> =
      yield call(classAlbumApi.fetchClassAlbumPhotos, {
        params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* classAlbumSaga() {
  yield all([
    takeEvery(ClassAlbumActionTypes.FETCH_CLASS_ALBUMS_SAGA, fetchClassAlbums),
    takeEvery(ClassAlbumActionTypes.FETCH_CLASS_ALBUM_SAGA, fetchClassAlbum),
    takeEvery(
      ClassAlbumActionTypes.FETCH_CLASS_ALBUM_PHOTOS_SAGA,
      fetchClassAlbumPhotos
    ),
  ]);
}

export default classAlbumSaga;
