import { authAxios, authV3Axios } from "@/utils/axios";

import type {
  FetchClassRegistrationOrderPayload,
  SubmitClassRegistrationOrderPayload,
  SubmitClassRegistrationOrderReschedulePayload,
  SubmitClassRegistrationOrderSickLeavePayload,
  SubmitDraftClassRegistrationOrderPayload,
  FetchFullClassRegistrationOrderPayload,
  FetchDraftClassRegistrationOrderPayload,
  CancelClassRegistrationOrderPayload,
} from "./classRegistrationOrder.api.types";

import type { AxiosResponseData } from "@/utils/axios";

const classRegistrationOrderApi = {
  fetchClassRegistrationOrder: (
    payload: FetchClassRegistrationOrderPayload
  ) => {
    return authV3Axios.get<AxiosResponseData>(
      "studentRegisterClasses/getOrder.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers,
      }
    );
  },
  submitClassRegistrationOrder: (
    payload: SubmitClassRegistrationOrderPayload
  ) => {
    return authV3Axios.post<AxiosResponseData>(
      "StudentRegisterClasses/setOrder.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  submitClassRegistrationOrderReschedule: (
    payload: SubmitClassRegistrationOrderReschedulePayload
  ) => {
    return authAxios.post<AxiosResponseData>(
      "RescheduleHistories/webReschedule.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  submitClassRegistrationOrderSickLeave: (
    payload: SubmitClassRegistrationOrderSickLeavePayload
  ) => {
    return authAxios.post<AxiosResponseData>(
      "SickLeaveHistories/webSubmit.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  submitDraftClassRegistrationOrder: (
    payload: SubmitDraftClassRegistrationOrderPayload
  ) => {
    return authV3Axios.post<AxiosResponseData>(
      "StudentRegisterClasses/setCartOrder.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchDraftClassRegistrationOrder: (
    payload: FetchDraftClassRegistrationOrderPayload
  ) => {
    return authV3Axios.post<AxiosResponseData>(
      "StudentRegisterClasses/isValidateCart.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchFullClassRegistrationOrder: (
    payload: FetchFullClassRegistrationOrderPayload
  ) => {
    return authAxios.get<AxiosResponseData>(
      "StudentRegisterClasses/getDetailDatesOrder.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  cancelClassRegistrationOrder: (
    payload: CancelClassRegistrationOrderPayload
  ) => {
    return authV3Axios.post<AxiosResponseData>(
      "Orders/cancel.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default classRegistrationOrderApi;
