import { CentreActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchCentresSagaAction,
} from "./types";

// ---- REDUCER ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CentreActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CentreActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CentreActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchCentresSaga = (
  payload?: FetchCentresSagaAction["payload"],
  meta?: FetchCentresSagaAction["meta"]
): FetchCentresSagaAction => ({
  type: CentreActionTypes.FETCH_CENTRES_SAGA,
  payload,
  meta,
});
