import { FetchProgramSagaAction, ProgramActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  // Saga
  FetchProgramsSagaAction,
  FetchDiscoveryProgramsSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ProgramActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ProgramActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ProgramActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: ProgramActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: ProgramActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: ProgramActionTypes.MAP_FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchProgramsSaga = (
  payload?: FetchProgramsSagaAction["payload"],
  meta?: FetchProgramsSagaAction["meta"]
): FetchProgramsSagaAction => ({
  type: ProgramActionTypes.FETCH_PROGRAMS_SAGA,
  payload,
  meta,
});

export const fetchProgramSaga = (
  payload: FetchProgramSagaAction["payload"],
  meta?: FetchProgramSagaAction["meta"]
): FetchProgramSagaAction => ({
  type: ProgramActionTypes.FETCH_PROGRAM_SAGA,
  payload,
  meta,
});

export const fetchDiscoveryProgramsSaga = (
  payload?: FetchDiscoveryProgramsSagaAction["payload"],
  meta?: FetchDiscoveryProgramsSagaAction["meta"]
): FetchDiscoveryProgramsSagaAction => ({
  type: ProgramActionTypes.FETCH_DISCOVERY_PROGRAMS_SAGA,
  payload,
  meta,
});
