import { ClassRegistrationOrderActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  RemoveDraftClassRegistrationOrderAction,
  RemoveSelectedDraftClassRegistrationOrderClassAction,
  SelectDraftClassRegistrationOrderAction,
  AddDraftClassRegistrationOrderAction,
  FetchDraftClassRegistrationOrderSucceededAction,
  SubmitDraftClassRegistrationOrderFailedAction,
  SubmitDraftClassRegistrationOrderSucceededAction,
  // saga
  FetchClassRegistrationOrderSagaAction,
  FetchDraftClassRegistrationOrderSagaAction,
  SubmitDraftClassRegistrationOrderSagaAction,
} from "./types";

// ---- REDUCER ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: ClassRegistrationOrderActionTypes.MAP_FETCH_FAILED,
  payload,
});

export const addDraftClassRegistrationOrder = (
  payload: AddDraftClassRegistrationOrderAction["payload"]
): AddDraftClassRegistrationOrderAction => ({
  type: ClassRegistrationOrderActionTypes.ADD_DRAFT_CLASS_REGISTRATION_ORDER,
  payload,
});

export const removeDraftClassRegistrationOrder = (
  payload?: RemoveDraftClassRegistrationOrderAction["payload"]
): RemoveDraftClassRegistrationOrderAction => ({
  type: ClassRegistrationOrderActionTypes.REMOVE_DRAFT_CLASS_REGISTRATION_ORDER,
  payload,
});

export const removeSelectedDraftClassRegistrationOrderClass =
  (): RemoveSelectedDraftClassRegistrationOrderClassAction => ({
    type: ClassRegistrationOrderActionTypes.REMOVE_SELECTED_DRAFT_CLASS_REGISTRATION_ORDER_CLASS,
  });

export const selectDraftClassRegistrationOrder = (
  payload?: SelectDraftClassRegistrationOrderAction["payload"]
): SelectDraftClassRegistrationOrderAction => ({
  type: ClassRegistrationOrderActionTypes.SELECT_DRAFT_CLASS_REGISTRATION_ORDER,
  payload,
});

export const submitDraftClassRegistrationOrderSucceeded =
  (): SubmitDraftClassRegistrationOrderSucceededAction => ({
    type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED,
  });

export const submitDraftClassRegistrationOrderFailed = (
  payload: SubmitDraftClassRegistrationOrderFailedAction["payload"]
): SubmitDraftClassRegistrationOrderFailedAction => ({
  type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_FAILED,
  payload,
});

export const fetchDraftClassRegistrationOrderSucceeded = (
  payload: FetchDraftClassRegistrationOrderSucceededAction["payload"]
): FetchDraftClassRegistrationOrderSucceededAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SUCCEEDED,
  payload,
});

// ---- SAGA ----

export const fetchDraftClassRegistrationOrderSaga = (
  payload?: FetchDraftClassRegistrationOrderSagaAction["payload"],
  meta?: FetchDraftClassRegistrationOrderSagaAction["meta"]
): FetchDraftClassRegistrationOrderSagaAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SAGA,
  payload,
  meta,
});

export const fetchClassRegistrationOrderSaga = (
  payload: FetchClassRegistrationOrderSagaAction["payload"]
): FetchClassRegistrationOrderSagaAction => ({
  type: ClassRegistrationOrderActionTypes.FETCH_CLASS_REGISTRATION_ORDER_SAGA,
  payload,
});

export const submitDraftClassRegistrationOrderSaga = (
  payload: SubmitDraftClassRegistrationOrderSagaAction["payload"],
  meta?: SubmitDraftClassRegistrationOrderSagaAction["meta"]
): SubmitDraftClassRegistrationOrderSagaAction => ({
  type: ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SAGA,
  payload,
  meta,
});
