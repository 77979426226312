import { bindActionCreators } from "redux";
import { toast, ToastContainer } from "react-toastify";
import { appWithTranslation } from "next-i18next";
import * as locales from "@mui/material/locale";
import { Nunito } from "next/font/google";
import { DefaultSeo } from "next-seo";
import intervalPlural from "i18next-intervalplural-postprocessor";

import eventBus from "@/services/eventBus";
import { eventBusCommonConstants } from "@/utils/constants";
import nextI18NextConfig from "@@/next-i18next.config";
import { createEmotionCacheApp, createEmotionCacheMui } from "@/libs";
import defaultTheme from "@/assets/theme";
import { commonConfig } from "@/utils/config";
import { storeAuthAction } from "@/store";
import store from "@/store";
import { storageService } from "@/services";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { TssCacheProvider } from "tss-react";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoadingScreenOverlay from "@/components/LoadingScreenOverlay";
import ErrorBoundary from "@/components/ErrorBoundary";
import AlertDialog from "@/components/AlertDialog";
import RouterLoadingLinearProgress from "@/components/RouterLoadingLinearProgress";
import SocketIOProvider from "@/contexts/SocketIO";

import { useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { useAppDispatch, useAppSelector, useIsMounted } from "@/hooks";

import { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";

import "@/assets/scss/app.scss";
import "moment/locale/zh-hk";
import "moment/locale/zh-cn";

import type { NextPage } from "next";
import type { UseTranslationResponse } from "react-i18next";
import type { AppProps } from "next/app";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (
    page: ReactElement,
    pageProps: P,
    appProps: {
      translation: UseTranslationResponse<"common">;
    }
  ) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
  emotionCacheMui?: EmotionCache;
  emotionCacheApp?: EmotionCache;
}

type SupportedLocales = keyof typeof locales;

const appFont = Nunito({
  weight: ["300", "400", "500", "600", "700", "800", "900"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-nunito",
});

const clientSideEmotionCacheMui = createEmotionCacheMui();
const clientSideEmotionCacheApp = createEmotionCacheApp();

const theme = createTheme(defaultTheme);

const CheckAuthUser = () => {
  const dispatch = useAppDispatch();

  // const $s_userAuthCheckingError = useAppSelector(
  //   (state) => state.auth.userAuthCheckingError
  // );

  const $s_authAction = useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch]
  );

  const handleTokenExpiration = () => {
    $s_authAction.signOut();
  };

  // useEffect(() => {
  //   if ($s_userAuthCheckingError && isMounted()) {
  //     toast.error($s_userAuthCheckingError);
  //   }
  // }, [$s_userAuthCheckingError]);

  useEffect(() => {
    $s_authAction.checkAuthSaga();
    eventBus.on(
      eventBusCommonConstants.AUTH_TOKEN_EXPIRATION,
      handleTokenExpiration
    );
    return () => {
      eventBus.remove(
        eventBusCommonConstants.AUTH_TOKEN_EXPIRATION,
        handleTokenExpiration
      );
    };
  }, []);

  const isMounted = useIsMounted();

  return null;
};

const InitializeMyAppEffect = () => {
  const theme = useTheme();

  const showVersionLog = () => {
    const logFontStyle = `
      font-family: ${theme.typography.fontFamily};
      font-size: 48px;
      font-weight: 900;`;

    setTimeout(
      console.log.bind(
        console,
        `%cC%cI%cD%cC %cK%cI%cD%cS\n%cv${commonConfig.APP_VERSION}`,
        `${logFontStyle}
        color: #38B045;`, // C
        `${logFontStyle} 
        color: #82C23D;`, // I
        `${logFontStyle}
        color: #8ABF54;`, // D
        `${logFontStyle}
        color: #CFDB33;`, // C
        `${logFontStyle}
        color: #FF7596;`, // K
        `${logFontStyle}
        color: #FFCC40;`, // I
        `${logFontStyle}
        color: #6ED9B8;`, // D
        `${logFontStyle}
        color: #AD82C4;`, // S
        `font-family: ${theme.typography.fontFamily};
        font-size: 10px;
        font-weight: 500;
        color: ${theme.palette.primary.main};`
      )
    );
  };

  useEffect(() => {
    showVersionLog();
  }, []);

  return null;
};

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const {
    Component,
    emotionCacheMui = clientSideEmotionCacheMui,
    emotionCacheApp = clientSideEmotionCacheApp,
    pageProps,
  } = props;

  const [queryClient] = useState(() => new QueryClient());

  const locale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      ""
    ) as SupportedLocales) ||
    nextI18NextConfig.i18n.defaultLocale.replace("-", "");

  const headerLocale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      "_"
    ) as string) || nextI18NextConfig.i18n.defaultLocale.replace("-", "_");

  const getLayout = Component.getLayout ?? ((page) => page);
  const translation = useTranslation();

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [theme, locale]
  );

  const curVersion = storageService.getLocalItem("version");

  if (curVersion !== commonConfig.APP_VERSION) {
    storageService.clearLocal();
    storageService.saveLocalItem("version", commonConfig.APP_VERSION);
  }

  return (
    <>
      <DefaultSeo
        defaultTitle={commonConfig.DOCUMENT_TITLE}
        titleTemplate={`%s | ${commonConfig.DOCUMENT_TITLE}`}
        openGraph={{
          type: "website",
          locale: headerLocale,
          // url: "",
          siteName: commonConfig.DOCUMENT_TITLE,
        }}
      />
      <style jsx global>{`
        html {
          font-family: ${appFont.style.fontFamily};
        }
      `}</style>
      <div className={appFont.variable}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <CacheProvider value={emotionCacheMui}>
              <TssCacheProvider value={emotionCacheApp}>
                <ThemeProvider theme={themeWithLocale}>
                  <SocketIOProvider>
                    <InitializeMyAppEffect />
                    <RouterLoadingLinearProgress />
                    <CssBaseline />
                    <CheckAuthUser />
                    <ErrorBoundary>
                      {getLayout(<Component {...pageProps} />, pageProps, {
                        translation,
                      })}
                    </ErrorBoundary>
                    <LoadingScreenOverlay />
                    <AlertDialog />
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar
                      closeOnClick
                    />
                  </SocketIOProvider>
                </ThemeProvider>
              </TssCacheProvider>
            </CacheProvider>
          </Provider>
        </QueryClientProvider>
      </div>
    </>
  );
};

export default appWithTranslation(MyApp, {
  ...nextI18NextConfig,
  use: [intervalPlural],
  interpolation: {
    ...(nextI18NextConfig as any)?.interpolation,
    format: (value, format) => {
      if (format === "lowerFirst" && typeof value === "string")
        return value.charAt(0).toLowerCase() + value.slice(1);
      return value;
    },
  },
});
