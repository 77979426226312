import { FetchClassAlbumSagaAction, ClassAlbumActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  // Saga
  FetchClassAlbumsSagaAction,
  FetchClassAlbumPhotosSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ClassAlbumActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ClassAlbumActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ClassAlbumActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: ClassAlbumActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: ClassAlbumActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: ClassAlbumActionTypes.MAP_FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchClassAlbumsSaga = (
  payload: FetchClassAlbumsSagaAction["payload"]
): FetchClassAlbumsSagaAction => ({
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUMS_SAGA,
  payload,
});

export const fetchClassAlbumSaga = (
  payload: FetchClassAlbumSagaAction["payload"]
): FetchClassAlbumSagaAction => ({
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUM_SAGA,
  payload,
});

export const fetchClassAlbumPhotosSaga = (
  payload: FetchClassAlbumPhotosSagaAction["payload"]
): FetchClassAlbumPhotosSagaAction => ({
  type: ClassAlbumActionTypes.FETCH_CLASS_ALBUM_PHOTOS_SAGA,
  payload,
});
