import { all, call, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { kidApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
} from "./action";
import {
  FetchClassesKidsSagaAction,
  FetchStaffKidSagaAction,
  FetchStaffKidsSagaAction,
  KidActionTypes,
  KidState,
} from "./types";

import type { FetchKidsSagaAction, FetchKidSagaAction } from "./types";
import type { AppState } from "../rootReducer";
import type { FetchKidsResponseParams } from "@/utils/apis/kid/kid.api.types";

function* fetchKids(action: FetchKidsSagaAction) {
  const { params, cancelToken } = action.payload;
  const { enableLoadMore = false, isReset = false } = action.meta || {};

  const scope = "kids";

  yield put(
    fetchRequested({
      scope,
      ...(isReset
        ? {
            data: [],
          }
        : {}),
    })
  );

  try {
    const { data: response }: Awaited<ReturnType<typeof kidApi.fetchKids>> =
      yield call(kidApi.fetchKids, {
        params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      if (enableLoadMore) {
        const kids: KidState["kids"] = yield select(
          (state: AppState) => state.kid.kids
        );
        (response.params.items as FetchKidsResponseParams["items"]).forEach(
          (kid) => {
            const existedClass = kids.some((c) => c.id === kid.id);
            !existedClass && kids.push(kid);
          }
        );
        yield put(
          fetchSucceeded({
            scope,
            data: kids,
            count: response.params.count,
          })
        );
      } else
        yield put(
          fetchSucceeded({
            scope,
            data: response.params.items,
            count: response.params.count,
          })
        );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchKid(action: FetchKidSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.id;
  const scope = "idToKid";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const { data: response }: Awaited<ReturnType<typeof kidApi.fetchKid>> =
      yield call(kidApi.fetchKid, {
        params: params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* fetchStaffKids(action: FetchStaffKidsSagaAction) {
  const { params, cancelToken } = action.payload;

  const scope = "staffKids";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof kidApi.fetchStaffKids>> = yield call(
      kidApi.fetchStaffKids,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchStaffKid(action: FetchStaffKidSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.id;
  const scope = "idToStaffKid";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const { data: response }: Awaited<ReturnType<typeof kidApi.fetchStaffKid>> =
      yield call(kidApi.fetchStaffKid, {
        params: params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* fetchClassesKids(action: FetchClassesKidsSagaAction) {
  const { params, cancelToken } = action.payload;

  const scope = "classesKids";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof kidApi.fetchClassesKids>> = yield call(
      kidApi.fetchClassesKids,
      {
        params,
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* kidSaga() {
  yield all([
    takeEvery(KidActionTypes.FETCH_KIDS_SAGA, fetchKids),
    takeEvery(KidActionTypes.FETCH_KID_SAGA, fetchKid),
    takeEvery(KidActionTypes.FETCH_STAFF_KIDS_SAGA, fetchStaffKids),
    takeEvery(KidActionTypes.FETCH_STAFF_KID_SAGA, fetchStaffKid),
    takeEvery(KidActionTypes.FETCH_CLASSES_KIDS_SAGA, fetchClassesKids),
  ]);
}

export default kidSaga;
