import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { centreApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import { CentreActionTypes } from "./types";

import type { FetchCentresSagaAction } from "./types";

function* fetchCentres(action: FetchCentresSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const scope = "centres";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof centreApi.fetchCentres>> = yield call(
      centreApi.fetchCentres,
      {
        params: params ?? {
          limit: 1000,
          page: 1,
        },
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.total,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* centreSaga() {
  yield all([takeEvery(CentreActionTypes.FETCH_CENTRES_SAGA, fetchCentres)]);
}

export default centreSaga;
