import {
  FetchClassesPayload,
  FetchClassesResponseParams,
  FetchKidClassPayload,
  FetchKidClassResponseParams,
  FetchStaffClassesPayload,
  FetchStaffClassesResponseParams,
  FetchStaffClassPayload,
  FetchStaffClassResponseParams,
  UpdateStaffClassKidAttendancePayload,
} from "@/utils/apis/class/class.api.types";

// Action Types

export enum ClassActionTypes {
  FETCH_REQUESTED = "@@class/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@class/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@class/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@class/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@class/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@class/MAP_FETCH_FAILED",

  RESET_MODULE_STATE = "@@class/RESET_MODULE_STATE",
  UPDATE_STAFF_CLASS_KID_ATTENDANCE_SUCCEEDED = "@@class/UPDATE_STAFF_CLASS_KID_ATTENDANCE_SUCCEEDED",
  // Saga
  FETCH_CLASSES_SAGA = "@@class/FETCH_CLASSES_SAGA",
  FETCH_STAFF_CLASSES_SAGA = "@@class/FETCH_STAFF_CLASSES_SAGA",
  FETCH_STAFF_CLASS_SAGA = "@@class/FETCH_STAFF_CLASS_SAGA",
  FETCH_KID_CLASS_SAGA = "@@class/FETCH_KID_CLASS_SAGA",
  UPDATE_STAFF_CLASS_KID_ATTENDANCE_SAGA = "@@class/UPDATE_STAFF_CLASS_KID_ATTENDANCE_SAGA",
}

// State

export interface ClassState {
  classes: FetchClassesResponseParams["items"];
  classesLoading: boolean;
  classesError: string;
  classesCount: number;

  staffClasses: FetchStaffClassesResponseParams["items"];
  staffClassesLoading: boolean;
  staffClassesError: string;
  staffClassesCount: number;

  idToStaffClassMap: {
    [id: string | number]: FetchStaffClassResponseParams;
  };
  idToStaffClassLoadingMap: {
    [id: string | number]: boolean;
  };
  idToStaffClassErrorMap: {
    [id: string | number]: string;
  };

  idToKidClassMap: {
    [id: string | number]: FetchKidClassResponseParams;
  };
  idToKidClassErrorMap: {
    [id: string | number]: string;
  };
  idToKidClassLoadingMap: {
    [id: string | number]: boolean;
  };
}

// ---- Reducer ----

export type FetchScope = "classes" | "staffClasses";
export type MapFetchScope = "idToStaffClass" | "idToKidClass";
export type ResetModuleStateScope = "classes";

export type MapFetchRequestedAction = {
  type: ClassActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ClassActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: ClassState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ClassActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

export type FetchRequestedAction = {
  type: ClassActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    data?: ClassState[FetchScope];
  };
};

export type FetchSucceededAction = {
  type: ClassActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ClassState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ClassActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type ResetModuleStateAction = {
  type: ClassActionTypes.RESET_MODULE_STATE;
  payload: ResetModuleStateScope | ResetModuleStateScope[];
};

export type UpdateStaffClassKidAttendanceSucceededAction = {
  type: ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SUCCEEDED;
  payload: UpdateStaffClassKidAttendancePayload["params"];
};

// ---- Saga ----

export type FetchClassesSagaAction = {
  type: ClassActionTypes.FETCH_CLASSES_SAGA;
  payload: FetchClassesPayload;
  meta?: {
    enableLoadMore?: boolean;
    isReset?: boolean;
  };
};

export type FetchStaffClassesSagaAction = {
  type: ClassActionTypes.FETCH_STAFF_CLASSES_SAGA;
  payload: FetchStaffClassesPayload;
  meta?: {
    resolve: Function;
  };
};

export type FetchStaffClassSagaAction = {
  type: ClassActionTypes.FETCH_STAFF_CLASS_SAGA;
  payload: FetchStaffClassPayload;
  meta?: {
    resolve: Function;
  };
};

export type FetchKidClassSagaAction = {
  type: ClassActionTypes.FETCH_KID_CLASS_SAGA;
  payload: FetchKidClassPayload;
  meta?: {
    resolve: Function;
  };
};

export type UpdateStaffClassKidAttendanceSagaAction = {
  type: ClassActionTypes.UPDATE_STAFF_CLASS_KID_ATTENDANCE_SAGA;
  payload: UpdateStaffClassKidAttendancePayload;
  meta: {
    resolve?: Function;
  };
};

export type ClassAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  | ResetModuleStateAction
  | UpdateStaffClassKidAttendanceSucceededAction
  //
  | FetchClassesSagaAction
  | FetchStaffClassesSagaAction
  | FetchStaffClassSagaAction
  | FetchKidClassSagaAction
  | UpdateStaffClassKidAttendanceSagaAction;
