import { authAxios, AxiosResponseData } from "@/utils/axios";

import type {
  FetchClassAlbumsPayload,
  FetchClassAlbumPayload,
  FetchClassAlbumPhotosPayload,
} from "./classAlbum.api.types";

const classAlbumApi = {
  fetchClassAlbums: (payload: FetchClassAlbumsPayload) => {
    return authAxios.get<AxiosResponseData>("cidcClasses/getAlbumTitle.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  fetchClassAlbum: (payload: FetchClassAlbumPayload) => {
    return authAxios.get<AxiosResponseData>("cidcClasses/getImageTitle.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
  fetchClassAlbumPhotos: (payload: FetchClassAlbumPhotosPayload) => {
    return authAxios.get<AxiosResponseData>("albums/getAlbums.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default classAlbumApi;
