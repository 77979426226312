declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { useMemo, forwardRef } from "react";

import { Container, ContainerProps, Theme } from "@mui/material";

import useStyles from "./AppContainer.styles";

export type AppContainerProps = Omit<ContainerProps, "disableGutters"> & {
  disableGutters?:
    | boolean
    | `${keyof Theme["breakpoints"]["values"]}Up`
    | `${keyof Theme["breakpoints"]["values"]}Down`;
};

const AppContainer = (
  props: AppContainerProps,
  ref: React.ForwardedRef<any>
) => {
  const { classes: muiClasses, disableGutters, ...rest } = props;
  const { classes, cx } = useStyles();

  const disableGutterClassName = useMemo(() => {
    if (typeof disableGutters === "string") {
      const className = (disableGutters.charAt(0).toUpperCase() +
        disableGutters.slice(1)) as Capitalize<string & typeof disableGutters>;
      return classes[`disableGutter${className}`];
    }
    return disableGutters === true ? classes.disableGutters : "";
  }, [disableGutters]);

  return (
    <Container
      maxWidth="lg"
      ref={ref}
      classes={{
        ...muiClasses,
        root: cx(disableGutterClassName, muiClasses?.root),
      }}
      {...rest}
    />
  );
};

const AppContainerWithRef = forwardRef(AppContainer);

export default AppContainerWithRef;
