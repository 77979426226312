import type { FetchUsersPayload } from "./user.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const userApi = {
  fetchUsers: (payload: FetchUsersPayload) => {
    return authAxios.get<AxiosResponseData>("users", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
}

export default userApi
