import { GeneralInfoActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchContactsSagaAction,
  FetchPrivacyPolicyAction,
  FetchAboutUsAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: GeneralInfoActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: GeneralInfoActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: GeneralInfoActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchContactsSaga = (
  payload?: FetchContactsSagaAction["payload"]
): FetchContactsSagaAction => ({
  type: GeneralInfoActionTypes.FETCH_CONTACTS_SAGA,
  payload,
});

export const fetchPrivacyPolicySaga = (
  payload?: FetchPrivacyPolicyAction["payload"]
): FetchPrivacyPolicyAction => ({
  type: GeneralInfoActionTypes.FETCH_PRIVACY_POLICY_SAGA,
  payload,
});

export const fetchAboutUsSaga = (
  payload?: FetchAboutUsAction["payload"]
): FetchAboutUsAction => ({
  type: GeneralInfoActionTypes.FETCH_ABOUT_US_SAGA,
  payload,
});
