import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { feedbackApi } from "@/utils/apis";

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action";
import { FeedbackActionTypes } from "./types";

import type { FetchFeedbacksSagaAction } from "./types";

function* fetchFeedbacks(action: FetchFeedbacksSagaAction) {
  const { params, cancelToken } = action.payload || {};

  yield put(
    fetchRequested({
      scope: "feedbacks",
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof feedbackApi.fetchFeedbacks>> = yield call(
      feedbackApi.fetchFeedbacks,
      {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "feedbacks",
          data: response.params.items,
          count: response.params.total,
        })
      );
      // yield put(
      //   fetchSucceeded({
      //     scope: "feedbacks",
      //     data: [...response.params.items],
      //     count: response.params.total,
      //   })
      // );
    } else {
      yield put(
        fetchFailed({
          scope: "feedbacks",
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope: "feedbacks",
        error: message,
      })
    );
  }
}

function* feedbackSaga() {
  yield all([
    takeEvery(FeedbackActionTypes.FETCH_FEEDBACKS_SAGA, fetchFeedbacks),
  ]);
}

export default feedbackSaga;
