import { AuthActionTypes } from "./types";
import { jwtService, storageService } from "@/services";
import { commonHelpers } from "@/utils/helpers";

import type { AuthState, AuthAction } from "./types";

export const initialState: AuthState = {
  user: null,
  userAuthChecking: true,
  userAuthCheckingError: "",
  userLoading: false,
  userError: "",
};

const reducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case AuthActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };
    }
    case AuthActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case AuthActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }

    case AuthActionTypes.CHECK_AUTH_REQUESTED: {
      return {
        ...state,
        userAuthChecking: true,
        userAuthCheckingError: "",
      };
    }
    case AuthActionTypes.CHECK_AUTH_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...action.payload,
        } as AuthState["user"],
        userAuthChecking: false,
      };
    }
    case AuthActionTypes.CHECK_AUTH_FAILED: {
      const { message, isNetworkError } = action.payload;

      if (!isNetworkError) jwtService.destroyToken();

      return {
        ...state,
        user: isNetworkError ? state.user : null,
        userAuthCheckingError: message,
        userAuthChecking: false,
        // !!isNetworkError,
      };
    }

    case AuthActionTypes.SIGN_IN_SUCCEEDED: {
      jwtService.saveToken(action.payload.token);
      document.cookie = `token=${action.payload.token};path=/`;
      storageService.destroyLocalItem("draftClassRegistrationOrder");

      return {
        ...state,
        user: {
          token: action.payload.token,
        } as unknown as AuthState["user"],
      };
    }

    case AuthActionTypes.SIGN_OUT: {
      jwtService.destroyToken();
      document.cookie = `token=;path=/`;
      storageService.destroyLocalItem("draftClassRegistrationOrder");

      return {
        ...state,
        user: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
