import AppButton from "@/components/AppButton";
import AppContainer from "@/components/AppContainer";
import AppLink from "@/components/AppLink";
import { storageService } from "@/services";
import { Typography } from "@mui/material";
import { Component } from "react";
import { withTranslation } from "next-i18next";

import { withStyles } from "tss-react/mui";

import type { WithTranslation } from "react-i18next";
import AppImageV2 from "@/components/AppImageV2";
import { commonConfig } from "@/utils/config";

interface Props extends WithTranslation<"translation", "common"> {
  children?: React.ReactNode;
  classes?: Partial<Record<"root", string>>;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error.message);
    storageService.destroyLocalItem("draftClassRegistrationOrder");
  }

  public render() {
    const { classes, children, t } = this.props;

    if (this.state.hasError) {
      return (
        <div>
          <AppContainer maxWidth="tablet">
            <div className={classes!.root}>
              <AppImageV2
                src="/images/svgs/error-500.svg"
                alt={`Error 500 | ${commonConfig.DOCUMENT_TITLE}`}
                width={200}
                height={200}
              />
              <Typography
                variant="nunitoExtraBold28"
                marginTop={5}
                marginBottom={5}
              >
                {t("Oops! something went wrong")}
              </Typography>
              <AppButton
                variant="contained"
                color="blue"
                component={AppLink}
                href="/"
              >
                {t("Go to Home")}
              </AppButton>
            </div>
          </AppContainer>
        </div>
      );
    }

    return children;
  }
}

const ErrorBoundaryStyled = withStyles(ErrorBoundary, (theme) => {
  return {
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

const ErrorBoundaryStyledWithTranslation =
  withTranslation()(ErrorBoundaryStyled);

export default ErrorBoundaryStyledWithTranslation;
