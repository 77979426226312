import { authAxios, AxiosResponseData } from "@/utils/axios";
import type {
  FetchNotificationsPayload,
  FetchNotificationPayload,
  DeleteNotificationPayload,
} from "./notification.api.types";

const notificationApi = {
  fetchNotifications: (payload: FetchNotificationsPayload) => {
    return authAxios.get<AxiosResponseData>(
      "systemMessages/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchNotification: (payload: FetchNotificationPayload) => {
    return authAxios.get<AxiosResponseData>("systemMessages/getDetail.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  removeNotifications: (payload: DeleteNotificationPayload) => {
    return authAxios.delete<AxiosResponseData>("systemMessages/remove.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default notificationApi;
