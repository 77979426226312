import {
  FetchAgeRangeCoursesPayload,
  FetchAgeRangeCoursesResponseParams,
  FetchCoursesPayload,
  FetchCoursesResponseParams,
} from "@/utils/apis/course/course.api.types";

// Action Types

export enum CourseActionTypes {
  FETCH_REQUESTED = "@@course/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@course/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@course/FETCH_FAILED",

  // Saga
  FETCH_COURSES_SAGA = "@@course/FETCH_COURSES_SAGA",
  FETCH_AGE_RANGE_COURSES_SAGA = "@@course/FETCH_AGE_RANGE_COURSES_SAGA",
}

// State

export interface CourseState {
  courses: FetchCoursesResponseParams["items"];
  coursesLoading: boolean;
  coursesError: string;
  coursesCount: number;

  ageRangeCourses: FetchAgeRangeCoursesResponseParams;
  ageRangeCoursesLoading: boolean;
  ageRangeCoursesError: string;
}

// ---- Reducer ----

export type FetchScope = "courses" | "ageRangeCourses";

export type FetchRequestedAction = {
  type: CourseActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: CourseActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CourseState[FetchSucceededAction["payload"]["scope"]];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: CourseActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga ----

export type FetchCoursesSagaAction = {
  type: CourseActionTypes.FETCH_COURSES_SAGA;
  payload?: FetchCoursesPayload;
};

export type FetchAgeRangeCoursesSagaAction = {
  type: CourseActionTypes.FETCH_AGE_RANGE_COURSES_SAGA;
  payload?: FetchAgeRangeCoursesPayload;
};

export type CourseAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAgeRangeCoursesSagaAction
  | FetchCoursesSagaAction;
