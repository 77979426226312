import { all, call, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { classApi, classRegistrationOrderApi } from "@/utils/apis";

import {
  fetchDraftClassRegistrationOrderSucceeded,
  fetchFailed,
  fetchRequested,
  mapFetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
  submitDraftClassRegistrationOrderFailed,
  submitDraftClassRegistrationOrderSucceeded,
} from "./action";
import {
  ClassRegistrationOrderActionTypes,
  SubmitDraftClassRegistrationOrderSagaAction,
} from "./types";

import type {
  ClassRegistrationOrderState,
  FetchClassRegistrationOrderSagaAction,
  FetchDraftClassRegistrationOrderSagaAction,
} from "./types";
import type { AppState } from "../rootReducer";
import type { FetchDraftClassRegistrationOrderPayload } from "@/utils/apis/classRegistrationOrder/classRegistrationOrder.api.types";

function* fetchDraftClassRegistrationOrder(
  action: FetchDraftClassRegistrationOrderSagaAction
) {
  const { cancelToken } = action.payload ?? {};
  const { resolve = () => {} } = action.meta ?? {};

  const draftClassRegistrationOrder: ClassRegistrationOrderState["draftClassRegistrationOrder"] =
    yield select(
      (state: AppState) =>
        state.classRegistrationOrder.draftClassRegistrationOrder
    );

  const scope = "draftClassRegistrationOrder";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof classRegistrationOrderApi.fetchDraftClassRegistrationOrder
      >
    > = yield call(classRegistrationOrderApi.fetchDraftClassRegistrationOrder, {
      params: {
        cart: draftClassRegistrationOrder.items.reduce((carts, item) => {
          const newCarts = [...carts];
          item.classes.forEach((_class) => {
            newCarts.push({
              kid_id: item.kid.id,
              cidc_class_id: _class.id,
              selected: _class.selected,
              number_of_lessons: _class.number_of_lessons,
              start_date: _class.start_date,
            });
          });
          return newCarts;
        }, [] as FetchDraftClassRegistrationOrderPayload["params"]["cart"]),
      },
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(fetchDraftClassRegistrationOrderSucceeded(response.params));
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }

    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchClassRegistrationOrder(
  action: FetchClassRegistrationOrderSagaAction
) {
  const { params, cancelToken } = action.payload || {};

  const key = params.order_id;
  const scope = "idToClassRegistrationOrder";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof classRegistrationOrderApi.fetchClassRegistrationOrder>
    > = yield call(classRegistrationOrderApi.fetchClassRegistrationOrder, {
      params: params,
      cancelToken,
    });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* submitDraftClassRegistrationOrderSaga(
  action: SubmitDraftClassRegistrationOrderSagaAction
) {
  const { cancelToken } = action.payload ?? {};
  const { resolve = () => {} } = action.meta || {};

  const draftClassRegistrationOrder: ClassRegistrationOrderState["draftClassRegistrationOrder"] =
    yield select(
      (state: AppState) =>
        state.classRegistrationOrder.draftClassRegistrationOrder
    );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof classRegistrationOrderApi.submitDraftClassRegistrationOrder
      >
    > = yield call(
      classRegistrationOrderApi.submitDraftClassRegistrationOrder,
      {
        params: {
          cart: draftClassRegistrationOrder.items.reduce((carts, item) => {
            const newCarts = [...carts];
            item.classes.forEach((_class) => {
              _class.selected &&
                newCarts.push({
                  kid_id: item.kid.id,
                  cidc_class_id: _class.id,
                  start_date: _class.start_date,
                  number_of_lessons: _class.number_of_lessons,
                });
            });
            return newCarts;
          }, [] as { kid_id: number; cidc_class_id: number; start_date: string; number_of_lessons: EmptySafeNumber }[]),
        },
        cancelToken,
      }
    );

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(submitDraftClassRegistrationOrderSucceeded());
    } else {
      yield put(submitDraftClassRegistrationOrderFailed(response.params));
    }
    resolve(response);
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";

    resolve({ message });
  }
}

function* classRegistrationOrderSaga() {
  yield all([
    takeEvery(
      ClassRegistrationOrderActionTypes.FETCH_DRAFT_CLASS_REGISTRATION_ORDER_SAGA,
      fetchDraftClassRegistrationOrder
    ),
    takeEvery(
      ClassRegistrationOrderActionTypes.FETCH_CLASS_REGISTRATION_ORDER_SAGA,
      fetchClassRegistrationOrder
    ),
    takeEvery(
      ClassRegistrationOrderActionTypes.SUBMIT_DRAFT_CLASS_REGISTRATION_ORDER_SAGA,
      submitDraftClassRegistrationOrderSaga
    ),
  ]);
}

export default classRegistrationOrderSaga;
