import { alpha, darken, rgbToHex } from "@mui/material/styles";
import _get from "lodash/get";
import { makeStyles } from "tss-react/mui";
import type { AppButtonProps } from "./AppButton";

type MakeStylesParams = {
  color: AppButtonProps["color"];
  textColor: AppButtonProps["textColor"];
  disableHoverEffect: boolean;
};

const defaultTextColorToThemePalettePathMap = {
  default: "common.white",
  textPrimary: "text.primary",
};

const useStyles = makeStyles<
  MakeStylesParams,
  | "edgeStart"
  | "edgeEnd"
  | "edgeTop"
  | "edgeBottom"
  | "edgeX"
  | "edgeY"
  | "edgeXY"
  | "disabled"
>({
  name: "appButton",
  uniqId: "kta7lJ",
})((theme, params, classes) => {
  const color =
    _get(theme.palette.common, params.color!) ||
    _get(theme.palette, params.color!) ||
    (params.color ?? "white");

  const textColor =
    _get(
      theme.palette,
      defaultTextColorToThemePalettePathMap[
        params.textColor! as keyof typeof defaultTextColorToThemePalettePathMap
      ]
    ) ||
    _get(theme.palette, params.textColor!) ||
    (params.textColor ?? "white");

  const disableHoverEffect = !!params.disableHoverEffect;

  return {
    root: {
      color,
      fontSize: theme.typography.button.fontSize,
      "&:hover": {
        backgroundColor: disableHoverEffect
          ? color
          : alpha(color, theme.palette.action.hoverOpacity),
      },
      [`&.${classes.disabled}`]: {
        color: theme.palette.common.grey,
      },
    },
    borderRadiusRounded: {
      borderRadius: theme.shape.borderRadius,
    },
    borderRadiusRoundedCircle: {
      borderRadius: "50px",
    },
    // sizeSmall: {
    //   minHeight: 40,
    //   padding: theme.spacing(1, 2.5),
    //   [`&.${classes.edgeStart}`]: {
    //     marginLeft: theme.spacing(-2.25),
    //   },
    //   [`&.${classes.edgeEnd}`]: {
    //     marginRight: theme.spacing(-2.25),
    //   },
    // },
    sizeMedium: {
      minHeight: 50,
      padding: theme.spacing(1.75, 3.75),
    },
    sizeSmall: {
      minHeight: 40,
      padding: theme.spacing(1, 2.5),
      [`&.${classes.edgeStart}`]: {
        marginLeft: theme.spacing(-2.5),
      },
      [`&.${classes.edgeEnd}`]: {
        marginRight: theme.spacing(-2.5),
      },
      [`&.${classes.edgeTop}`]: {
        marginTop: theme.spacing(-1),
      },
      [`&.${classes.edgeBottom}`]: {
        marginBottom: theme.spacing(-1.75),
      },
      [`&.${classes.edgeX}`]: {
        marginLeft: theme.spacing(-2.5),
        marginRight: theme.spacing(-2.5),
      },
      [`&.${classes.edgeY}`]: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
      },
      [`&.${classes.edgeXY}`]: {
        marginLeft: theme.spacing(-2.5),
        marginRight: theme.spacing(-2.5),
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
      },
    },
    // sizeLarge: {
    //   minHeight: 60,
    //   padding: theme.spacing(2, 3.75),
    //   [`&.${classes.edgeStart}`]: {
    //     marginLeft: theme.spacing(-3.75),
    //   },
    //   [`&.${classes.edgeEnd}`]: {
    //     marginRight: theme.spacing(-3.75),
    //   },
    // },
    contained: {
      backgroundColor: color,
      color: textColor,
      "&:hover": {
        backgroundColor: disableHoverEffect
          ? color
          : darken(color, theme.palette.contrastThreshold / 10),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: theme.palette.common.grey,
        color: theme.palette.common.white,
      },
    },
    outlined: {
      border: `1px solid ${color}`,
      color: params.textColor === "default" ? color : textColor,
      "&:hover": !disableHoverEffect
        ? {
            backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
          }
        : undefined,
    },
    containedTonal: {
      backgroundColor: alpha(color, 0.15),
      color: params.textColor === "default" ? color : textColor,
      "&:hover": {
        backgroundColor: disableHoverEffect
          ? alpha(color, 0.15)
          : darken(
              rgbToHex(alpha(color, 0.15)),
              theme.palette.contrastThreshold / 10
            ),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: darken(
          rgbToHex(alpha(theme.palette.common.grey, 0.15)),
          theme.palette.contrastThreshold / 10
        ),
      },
    },
    elevation: {
      boxShadow: `0px 7px 20px ${color}`,
      [`&.${classes.disabled}`]: {
        boxShadow: `0px 7px 20px ${theme.palette.common.grey}`,
      },
    },

    edgeStart: {
      marginLeft: theme.spacing(-3.75),
    },
    edgeEnd: {
      marginRight: theme.spacing(-3.75),
    },
    edgeTop: {
      marginTop: theme.spacing(-1.75),
    },
    edgeBottom: {
      marginBottom: theme.spacing(-1.75),
    },
    edgeX: {
      marginLeft: theme.spacing(-3.75),
      marginRight: theme.spacing(-3.75),
    },
    edgeY: {
      marginTop: theme.spacing(-1.75),
      marginBottom: theme.spacing(-1.75),
    },
    edgeXY: {
      marginLeft: theme.spacing(-3.75),
      marginRight: theme.spacing(-3.75),
      marginTop: theme.spacing(-1.75),
      marginBottom: theme.spacing(-1.75),
    },
    disabled: {},
  };
});

export default useStyles;
