declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { commonHelpers } from "@/utils/helpers";

import { Button } from "@mui/material";

import type { ButtonProps } from "@mui/material";

import useStyles from "./AppButton.styles";

type ButtonColor =
  | "blue"
  | "orange"
  | "green"
  | "darkPink"
  | "darkBg"
  | "white"
  | "black";
type CheckboxEdge = "start" | "end" | "top" | "bottom" | "x" | "y" | "xy";
type ButtonTextColor = "default" | "textPrimary";

type CustomButtonProps<
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
> = {
  borderRadius?: "rounded" | "roundedCircle";
  edge?: CheckboxEdge | CheckboxEdge[];
  variant?: "text" | "contained" | "outlined" | "containedTonal";
  size?: "small" | "medium";
  color?: C;
  textColor?: T;
  elevation?: boolean;
  disableHoverEffect?: boolean;
};

export type AppButtonProps<
  D extends React.ElementType = "button",
  P = {},
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
> = CustomButtonProps<C, T> &
  Omit<ButtonProps<D, P>, "size" | keyof CustomButtonProps<C, T>> & {
    component?: D;
  };

const AppButton = <
  D extends React.ElementType = "button",
  P = {},
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
>(
  props: AppButtonProps<D, P, C, T>,
  ref: React.ForwardedRef<any>
) => {
  const {
    borderRadius = "rounded",
    edge,
    classes: muiClasses,
    className,
    variant = "text",
    color = "blue",
    textColor = "default",
    elevation,
    size = "medium",
    disableHoverEffect = false,
    ...rest
  } = props;

  const { classes, cx } = useStyles({
    color: color as any,
    textColor: textColor as any,
    disableHoverEffect,
  });

  const edges = Array.isArray(edge)
    ? edge
    : !commonHelpers.isEmpty(edge)
    ? [edge!]
    : [];

  const appButtonClasses = cx(
    classes.root,
    {
      [classes.borderRadiusRoundedCircle]: borderRadius === "roundedCircle",
      [classes.borderRadiusRounded]: borderRadius === "rounded",
      [classes.contained]: variant === "contained",
      [classes.outlined]: variant === "outlined",
      [classes.containedTonal]: variant === "containedTonal",
      [classes.elevation]: !!elevation,
      [classes.edgeStart]: edge === "start",
      [classes.edgeEnd]: edge === "end",
      [classes.edgeStart]: edges.includes("start"),
      [classes.edgeEnd]: edges.includes("end"),
      [classes.edgeTop]: edges.includes("top"),
      [classes.edgeBottom]: edges.includes("bottom"),
      [classes.edgeX]: edges.includes("x"),
      [classes.edgeY]: edges.includes("y"),
      [classes.edgeXY]: edges.includes("xy"),
    },
    className
  );

  return (
    <Button
      ref={ref}
      classes={{
        ...muiClasses,
        disabled: cx(classes.disabled, muiClasses?.disabled),
        root: cx(appButtonClasses, muiClasses?.root),
        sizeMedium: cx(classes.sizeMedium, muiClasses?.sizeMedium),
        sizeSmall: cx(classes.sizeSmall, muiClasses?.sizeSmall),
      }}
      {...rest}
      size={size}
    />
  );
};

const AppButtonWithRef = forwardRef(AppButton);

export default AppButtonWithRef;
