import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { FetchNewsPayload, FetchNewPayload } from "./new.api.types";

const newApi = {
  fetchNews: (payload: FetchNewsPayload) => {
    return commonAxios.get<AxiosResponseData>("news/getListPagination.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  fetchNew: (payload: FetchNewPayload) => {
    return commonAxios.get<AxiosResponseData>("news/getNewsById.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
      headers: payload.headers,
    });
  },
};

export default newApi;
