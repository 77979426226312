import { useEffect } from "react";
import _isEqual from "lodash/isEqual";

import { commonHelpers } from "@/utils/helpers";

import { useTranslation } from "next-i18next";
import useIsMounted from "./useIsMounted";
import useDeepCompareEffect from "./useDeepCompareEffect";

type CacheTime = "infinity" | number;

type UseReduxQueryCacheOptions<D, P = {}> = {
  cacheTime?: CacheTime;
  data?: D;
  params?: P;
  loading?: boolean;
  disabled?: boolean;
};

const cache = new Map<
  string,
  {
    lang?: string;
    timeOnRefetch?: number;
    params?: any;
  }
>();

const useReduxQueryCache = <D, P = {}>(
  key: string,
  queryFn: (payload: { params: P }) => void,
  options?: UseReduxQueryCacheOptions<D, P>
) => {
  const { data, params, loading, disabled, cacheTime } = options || {};

  const { i18n } = useTranslation();

  useEffect(() => {
    if (!isMounted()) return;
    const cacheKeyState = cache.get(key) || {};
    const nowTime = new Date().getTime();
    cache.set(key, {
      ...cacheKeyState,
      timeOnRefetch:
        commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
          ? 0
          : nowTime + (cacheTime as number),
    });
  }, [cacheTime]);

  useDeepCompareEffect(() => {
    const nowTime = new Date().getTime();
    let isTheFirstTime = false;
    if (!cache.has(key)) {
      isTheFirstTime = true;
      cache.set(key, {
        lang: i18n.language,
        params,
        timeOnRefetch:
          commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
            ? 0
            : nowTime + (cacheTime as number),
      });
    }

    const cacheKeyState = cache.get(key) || {};

    if (
      !loading &&
      !disabled &&
      ((typeof data !== "undefined" && commonHelpers.isEmpty(data)) ||
        (!commonHelpers.isEmpty(data) &&
          cacheTime !== "infinity" &&
          (cacheKeyState?.timeOnRefetch || 0) < nowTime) ||
        isTheFirstTime ||
        !_isEqual(params, cacheKeyState.params) ||
        cacheKeyState.lang !== i18n.language)
    ) {
      cache.set(key, {
        ...cacheKeyState,
        lang: i18n.language,
        timeOnRefetch:
          commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
            ? 0
            : nowTime + (cacheTime as number),
        params,
      });
      queryFn({
        params: params as any,
      });
    }
  }, [key, params, disabled, i18n.language]);

  const isMounted = useIsMounted();

  return {
    refetch: queryFn,
  };
};

export default useReduxQueryCache;
