declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;

  function memo<P extends object, C = FunctionComponent<P>>(
    Component: C,
    propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean
  ): C;
}

import { forwardRef, memo, useEffect, useState } from "react";
import _isEqual from "lodash/isEqual";

import { commonConfig } from "@/utils/config";

import Image from "next/image";

import useStyles from "./AppImageV2.styles";

import type { ImageProps } from "next/image";

export type AppImageV2Props = {
  objectFit?: "fill" | "contain" | "cover" | "scale-down" | "none" | string;
  objectPosition?: string;
  defaultImgSrc?: ImageProps["src"];
} & ImageProps;

const AppImageV2 = (props: AppImageV2Props, ref: React.ForwardedRef<any>) => {
  const {
    className,
    src,
    width,
    height,
    defaultImgSrc = "/images/logo.svg",
    alt,
    objectFit,
    objectPosition,
    onError,
    ...rest
  } = props;

  const [imgSrc, setImgSrc] = useState(src);
  const [isErrorImg, setIsErrorImg] = useState(false);

  const { classes, cx } = useStyles({
    objectFit,
    objectPosition,
    width,
    height,
  });

  useEffect(() => {
    setImgSrc(src);
    setIsErrorImg(false);
  }, [src]);

  return (
    <Image
      ref={ref}
      {...rest}
      width={width}
      height={height}
      className={cx(
        classes.root,
        classes.img,
        isErrorImg && classes.errorImg,
        className
      )}
      src={imgSrc || "/"}
      alt={alt || commonConfig.DOCUMENT_TITLE}
      onError={(event: any) => {
        setIsErrorImg(true);
        setImgSrc(defaultImgSrc);
        onError && onError(event);
      }}
    />
  );
};

const AppImageV2WithRef = forwardRef(AppImageV2);

export default memo(AppImageV2WithRef, _isEqual);
