import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchAboutUsPayload,
  FetchContactsPayload,
  FetchPrivacyPolicyPayload,
} from "./generalInfo.api.types";

const generalInfoApi = {
  fetchAboutUs: (payload: FetchAboutUsPayload) => {
    return commonAxios.get<AxiosResponseData>("abouts/getDetail.json", {
      cancelToken: payload.cancelToken,
    });
  },
  fetchPrivacyPolicy: (payload: FetchPrivacyPolicyPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "PrivacyPolicies/getDetail.json",
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchContacts: (payload: FetchContactsPayload) => {
    return commonAxios.get<AxiosResponseData>("contacts/getList.json", {
      cancelToken: payload.cancelToken,
    });
  },
};

export default generalInfoApi;
