import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type {
  FetchCoursesPayload,
  FetchAgeRangeCoursesPayload,
} from "./course.api.types";

const courseApi = {
  fetchCourses: (payload: FetchCoursesPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "courses/getListPagination.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchAgeRangeCourses: (payload: FetchAgeRangeCoursesPayload) => {
    return commonAxios.get<AxiosResponseData>("courses/filter.json", {
      cancelToken: payload.cancelToken,
    });
  },
};

export default courseApi;
