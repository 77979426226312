import { NotificationActionTypes } from "./types";

import { commonHelpers } from "@/utils/helpers";

import type { NotificationState, NotificationAction } from "./types";

export const initialState: NotificationState = {
  notifications: [],
  notificationsError: "",
  notificationsLoading: false,
  notificationsCount: 0,

  idToNotificationErrorMap: {},
  idToNotificationLoadingMap: {},
  idToNotificationMap: {},

  unreadNotificationCount: 0,
};

const reducer = (state = initialState, action: NotificationAction) => {
  switch (action.type) {
    case NotificationActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };
    }
    case NotificationActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case NotificationActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }

    case NotificationActionTypes.MAP_FETCH_REQUESTED: {
      const { scope, key } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: true,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: "",
      };
      Object.entries(loadingMap).length >= 100 &&
        delete loadingMap[Object.entries(loadingMap)[0]?.[0]];
      Object.entries(errorMap).length >= 100 &&
        delete errorMap[Object.entries(errorMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }
    case NotificationActionTypes.MAP_FETCH_SUCCEEDED: {
      const { scope, key, data } = action.payload;
      const dataMap = {
        ...state[`${scope}Map`],
        [key]: data,
      };
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      Object.entries(dataMap).length >= 100 &&
        delete dataMap[Object.entries(dataMap)[0]?.[0]];

      return {
        ...state,
        [`${scope}Map`]: dataMap,
        [`${scope}LoadingMap`]: loadingMap,
      };
    }
    case NotificationActionTypes.MAP_FETCH_FAILED: {
      const { scope, key, error } = action.payload;
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      };
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: error,
      };

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      };
    }

    case NotificationActionTypes.SET_UNREAD_NOTIFICATION_COUNT: {
      return {
        ...state,
        unreadNotificationCount: action.payload,
      };
    }

    case NotificationActionTypes.SET_NOTIFICATIONS_UNREAD_TO_READ: {
      const notifications = [...state.notifications].map((notification) => {
        if (notification.id === action.payload) {
          notification.notification.is_read = 1;
        }
        return notification;
      });

      return {
        ...state,
        notifications,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
