import {
  FetchProfessionalsPayload,
  FetchProfessionalPayload,
  FetchProfessionalsResponseParams,
  FetchProfessionalResponseParams,
} from "@/utils/apis/professional/professional.api.types";

// Action Types

export enum ProfessionalActionTypes {
  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@branch/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@branch/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@branch/MAP_FETCH_FAILED",

  // Saga
  FETCH_PROFESSIONALS_SAGA = "@@branch/FETCH_PROFESSIONALS_SAGA",
  FETCH_PROFESSIONAL_SAGA = "@@branch/FETCH_PROFESSIONAL_SAGA",
}

// State

export interface ProfessionalState {
  professionals: FetchProfessionalsResponseParams["items"];
  professionalsLoading: boolean;
  professionalsError: string;
  professionalsCount: number;

  idToProfessionalMap: {
    [id: string | number]: FetchProfessionalResponseParams;
  };
  idToProfessionalLoadingMap: {
    [id: string | number]: boolean;
  };
  idToProfessionalErrorMap: {
    [id: string | number]: string;
  };
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: ProfessionalActionTypes.FETCH_REQUESTED;
  payload: {
    scope: "professionals";
  };
};

export type FetchSucceededAction = {
  type: ProfessionalActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: "professionals";
    data: ProfessionalState[FetchSucceededAction["payload"]["scope"]];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ProfessionalActionTypes.FETCH_FAILED;
  payload: {
    scope: "professionals";
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: ProfessionalActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: "idToProfessional";
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ProfessionalActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: "idToProfessional";
    key: string | number;
    data: ProfessionalState[`${MapFetchSucceededAction["payload"]["scope"]}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ProfessionalActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: "idToProfessional";
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchProfessionalsSagaAction = {
  type: ProfessionalActionTypes.FETCH_PROFESSIONALS_SAGA;
  payload: FetchProfessionalsPayload;
};

export type FetchProfessionalSagaAction = {
  type: ProfessionalActionTypes.FETCH_PROFESSIONAL_SAGA;
  payload: FetchProfessionalPayload;
};

export type ProfessionalAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchProfessionalsSagaAction
  | FetchProfessionalSagaAction;
