export const AUTH_TOKEN_KEY = "token";
export const USER_PROFILE_KEY = "user";

export const ROLE_PARENT_ID = 1;
export const ROLE_STAFF_ID = 2;

export const roleLabelMap = {
  [ROLE_PARENT_ID]: "Parent",
  [ROLE_STAFF_ID]: "Staff",
} as {
  [key: number]: string;
};
