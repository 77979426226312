import commonAxios from "@/utils/axios/common.axios";

import type { FetchRelationshipsPayload } from "./relationship.api.types";
import { AxiosResponseData } from "@/utils/axios";

const relationshipApi = {
  fetchRelationships: (payload: FetchRelationshipsPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "relationships/getListPagination.json",
      {
        ...payload,
      }
    );
  },
};

export default relationshipApi;
