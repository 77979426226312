import { CourseActionTypes } from "./types";

import { commonHelpers } from "@/utils/helpers";

import type { CourseState, CourseAction } from "./types";

export const initialState: CourseState = {
  courses: [],
  coursesError: "",
  coursesLoading: false,
  coursesCount: 0,

  ageRangeCourses: [],
  ageRangeCoursesError: "",
  ageRangeCoursesLoading: false,
};

const reducer = (state = initialState, action: CourseAction) => {
  switch (action.type) {
    case CourseActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload;

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      };
    }
    case CourseActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }

    case CourseActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        [`${scope}Error`]: error,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
