import { NotificationActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  SetUnreadNotificationCountAction,
  SetNotificationsUnreadToReadAction,
  // Saga
  FetchNotificationsSagaAction,
  FetchNotificationSagaAction,
  UpdateUnreadNotificationCountByAuthUserSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: NotificationActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: NotificationActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: NotificationActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: NotificationActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: NotificationActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: NotificationActionTypes.MAP_FETCH_FAILED,
  payload,
});

export const setUnreadNotificationCountByAuthUser = (
  payload: SetUnreadNotificationCountAction["payload"]
): SetUnreadNotificationCountAction => ({
  type: NotificationActionTypes.SET_UNREAD_NOTIFICATION_COUNT,
  payload,
});

export const setNotificationsUnreadToRead = (
  payload: SetNotificationsUnreadToReadAction["payload"]
): SetNotificationsUnreadToReadAction => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS_UNREAD_TO_READ,
  payload,
});

// ---- SAGA ----

export const fetchNotificationsSaga = (
  payload: FetchNotificationsSagaAction["payload"],
  meta?: FetchNotificationsSagaAction["meta"]
): FetchNotificationsSagaAction => ({
  type: NotificationActionTypes.FETCH_NOTIFICATIONS_SAGA,
  payload,
  meta,
});

export const fetchNotificationSaga = (
  payload: FetchNotificationSagaAction["payload"]
): FetchNotificationSagaAction => ({
  type: NotificationActionTypes.FETCH_NOTIFICATION_SAGA,
  payload,
});

export const updateUnreadNotificationCountByAuthUserSaga = (
  payload: UpdateUnreadNotificationCountByAuthUserSagaAction["payload"]
): UpdateUnreadNotificationCountByAuthUserSagaAction => ({
  type: NotificationActionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT_BY_AUTH_USER_SAGA,
  payload,
});
