import {
  SignInPayload,
  FetchProfileResponseParams,
  SaveUserProfilePayload,
  FetchUserProfilePayload,
} from "@/utils/apis/auth";

export enum AuthActionTypes {
  SIGN_IN_SUCCEEDED = "@@auth/SIGN_IN_SUCCEEDED",

  CHECK_AUTH_REQUESTED = "@@auth/CHECK_AUTH_REQUESTED",
  CHECK_AUTH_SUCCEEDED = "@@auth/CHECK_AUTH_SUCCEEDED",
  CHECK_AUTH_FAILED = "@@auth/CHECK_AUTH_FAILED",

  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  SIGN_OUT = "@@auth/SIGN_OUT",

  // Saga
  CHECK_AUTH_SAGA = "@@auth/CHECK_AUTH_SAGA",
  SIGN_IN_SAGA = "@@auth/SIGN_IN_SAGA",
  FETCH_USER_PROFILE_SAGA = "@@auth/FETCH_USER_PROFILE_SAGA",
  SAVE_USER_PROFILE_SAGA = "@@auth/SAVE_USER_PROFILE_SAGA",
}

// State

export interface AuthState {
  user: FetchProfileResponseParams | null;
  userAuthChecking: boolean;
  userAuthCheckingError: string;
  userLoading: boolean;
  userError: "";
}

// ---- Reducer Action ----

export type FetchScope = "user";

export type FetchRequestedAction = {
  type: AuthActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: AuthActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AuthState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: AuthActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type SignInSucceededAction = {
  type: AuthActionTypes.SIGN_IN_SUCCEEDED;
  payload: {
    id: number;
    email: string;
    token: string;
  };
};

export type CheckAuthRequestedAction = {
  type: AuthActionTypes.CHECK_AUTH_REQUESTED;
};

export type CheckAuthFailedAction = {
  type: AuthActionTypes.CHECK_AUTH_FAILED;
  payload: {
    message: string;
    isNetworkError?: boolean;
  };
};

export type CheckAuthSucceededAction = {
  type: AuthActionTypes.CHECK_AUTH_SUCCEEDED;
  payload: AuthState["user"] | null;
};

export type SignOutAction = {
  type: AuthActionTypes.SIGN_OUT;
};

// ---- Saga Action ----

export type FetchUserProfileSagaAction = {
  type: AuthActionTypes.FETCH_USER_PROFILE_SAGA;
  payload?: FetchUserProfilePayload;
};

export type SaveUserProfileSagaAction = {
  type: AuthActionTypes.SAVE_USER_PROFILE_SAGA;
  payload: SaveUserProfilePayload;
  meta?: {
    resolve?: Function;
  };
};

export type SignInSagaAction = {
  type: AuthActionTypes.SIGN_IN_SAGA;
  payload: SignInPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CheckAuthSagaAction = {
  type: AuthActionTypes.CHECK_AUTH_SAGA;
};

export type AuthAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | CheckAuthRequestedAction
  | CheckAuthSucceededAction
  | CheckAuthFailedAction
  | SignInSucceededAction
  | SignOutAction
  //
  | CheckAuthSagaAction
  | SignInSagaAction
  | FetchUserProfileSagaAction
  | SaveUserProfileSagaAction;
