import { all, call, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { notificationApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  setUnreadNotificationCountByAuthUser,
  mapFetchRequested,
  mapFetchSucceeded,
  mapFetchFailed,
  setNotificationsUnreadToRead,
} from "./action";
import { NotificationActionTypes } from "./types";

import type {
  FetchNotificationsSagaAction,
  FetchNotificationSagaAction,
  UpdateUnreadNotificationCountByAuthUserSagaAction,
} from "./types";

import type { AppState } from "../rootReducer";

function* fetchNotifications(action: FetchNotificationsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { disableLoading } = action.meta || {};

  const scope = "notifications";

  if (!disableLoading)
    yield put(
      fetchRequested({
        scope,
      })
    );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.fetchNotifications>> =
      yield call(notificationApi.fetchNotifications, {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* updateUnreadNotificationCountByAuthUser(
  action: UpdateUnreadNotificationCountByAuthUserSagaAction
) {
  const { unread_notification_count, user_id } = action.payload;

  const authUser: AppState["auth"]["user"] = yield select(
    (state: AppState) => state.auth.user
  );

  if (authUser?.id === user_id) {
    yield put(setUnreadNotificationCountByAuthUser(unread_notification_count));
  }
}

function* fetchNotification(action: FetchNotificationSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.id;
  const scope = "idToNotification";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  yield put(setNotificationsUnreadToRead(params.id));

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.fetchNotification>> =
      yield call(notificationApi.fetchNotification, {
        params: params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* notificationSaga() {
  yield all([
    takeEvery(
      NotificationActionTypes.FETCH_NOTIFICATIONS_SAGA,
      fetchNotifications
    ),
    takeEvery(
      NotificationActionTypes.FETCH_NOTIFICATION_SAGA,
      fetchNotification
    ),
    takeEvery(
      NotificationActionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT_BY_AUTH_USER_SAGA,
      updateUnreadNotificationCountByAuthUser
    ),
  ]);
}

export default notificationSaga;
