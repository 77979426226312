import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { professionalApi } from "@/utils/apis";

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
} from "./action";
import { ProfessionalActionTypes } from "./types";

import type {
  FetchProfessionalsSagaAction,
  FetchProfessionalSagaAction,
} from "./types";

function* fetchProfessionals(action: FetchProfessionalsSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const scope = "professionals";

  yield put(
    fetchRequested({
      scope,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof professionalApi.fetchProfessionals>> =
      yield call(professionalApi.fetchProfessionals, {
        params: params ?? {
          limit: 100,
          page: 1,
        },
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params.items,
          count: response.params.count,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
  }
}

function* fetchProfessional(action: FetchProfessionalSagaAction) {
  const { params, cancelToken } = action.payload || {};

  const key = params.id;
  const scope = "idToProfessional";

  yield put(
    mapFetchRequested({
      scope,
      key,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof professionalApi.fetchProfessional>> =
      yield call(professionalApi.fetchProfessional, {
        params: params,
        cancelToken,
      });

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.params,
        })
      );
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        })
      );
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : "";
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      })
    );
  }
}

function* professionalSaga() {
  yield all([
    takeEvery(
      ProfessionalActionTypes.FETCH_PROFESSIONALS_SAGA,
      fetchProfessionals
    ),
    takeEvery(
      ProfessionalActionTypes.FETCH_PROFESSIONAL_SAGA,
      fetchProfessional
    ),
  ]);
}

export default professionalSaga;
