import { KidActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  // Saga
  FetchKidsSagaAction,
  FetchKidSagaAction,
  FetchStaffKidsSagaAction,
  FetchStaffKidSagaAction,
  FetchClassesKidsSagaAction,
} from "./types";

// ---- REDUCER ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: KidActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: KidActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: KidActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: KidActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: KidActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: KidActionTypes.MAP_FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchKidsSaga = (
  payload: FetchKidsSagaAction["payload"],
  meta?: FetchKidsSagaAction["meta"]
): FetchKidsSagaAction => ({
  type: KidActionTypes.FETCH_KIDS_SAGA,
  payload,
  meta,
});

export const fetchKidSaga = (
  payload: FetchKidSagaAction["payload"]
): FetchKidSagaAction => ({
  type: KidActionTypes.FETCH_KID_SAGA,
  payload,
});

export const fetchStaffKidsSaga = (
  payload: FetchStaffKidsSagaAction["payload"]
): FetchStaffKidsSagaAction => ({
  type: KidActionTypes.FETCH_STAFF_KIDS_SAGA,
  payload,
});

export const fetchStaffKidSaga = (
  payload: FetchStaffKidSagaAction["payload"]
): FetchStaffKidSagaAction => ({
  type: KidActionTypes.FETCH_STAFF_KID_SAGA,
  payload,
});

export const fetchClassesKidsSaga = (
  payload: FetchClassesKidsSagaAction["payload"]
): FetchClassesKidsSagaAction => ({
  type: KidActionTypes.FETCH_CLASSES_KIDS_SAGA,
  payload,
});
