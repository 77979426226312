import { NewActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  MapFetchFailedAction,
  // Saga
  FetchNewsSagaAction,
  FetchNewSagaAction,
} from "./types";

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: NewActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: NewActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: NewActionTypes.FETCH_FAILED,
  payload,
});

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"]
): MapFetchRequestedAction => ({
  type: NewActionTypes.MAP_FETCH_REQUESTED,
  payload,
});

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"]
): MapFetchSucceededAction => ({
  type: NewActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
});

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"]
): MapFetchFailedAction => ({
  type: NewActionTypes.MAP_FETCH_FAILED,
  payload,
});

// ---- SAGA ----

export const fetchNewsSaga = (
  payload: FetchNewsSagaAction["payload"],
  meta?: FetchNewsSagaAction["meta"]
): FetchNewsSagaAction => ({
  type: NewActionTypes.FETCH_NEWS_SAGA,
  payload,
  meta,
});

export const fetchNewSaga = (
  payload: FetchNewSagaAction["payload"]
): FetchNewSagaAction => ({
  type: NewActionTypes.FETCH_NEW_SAGA,
  payload,
});
