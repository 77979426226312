import {
  FetchRegisterTermsAndConditionsContentPayload,
  FetchRegisterTermsAndConditionsContentResponseData,
} from "@/utils/apis/common/common.api.types";

// Action Types

export enum CommonActionTypes {
  FETCH_REQUESTED = "@@common/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@common/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@common/FETCH_FAILED",

  // Saga
  FETCH_REGISTER_TERMS_AND_CONDITIONS_CONTENT_SAGA = "@@common/FETCH_REGISTER_TERMS_AND_CONDITIONS_CONTENT_SAGA",
}

// State

export interface CommonState {
  registerTermsAndConditionsContent: FetchRegisterTermsAndConditionsContentResponseData | null;
  registerTermsAndConditionsContentLoading: boolean;
  registerTermsAndConditionsContentError: string;
}

// ---- Reducer ----

type FetchScope = "registerTermsAndConditionsContent";

export type FetchRequestedAction = {
  type: CommonActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: CommonActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CommonState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: CommonActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga ----

export type FetchRegisterTermsAndConditionsContentSagaAction = {
  type: CommonActionTypes.FETCH_REGISTER_TERMS_AND_CONDITIONS_CONTENT_SAGA;
  payload?: FetchRegisterTermsAndConditionsContentPayload;
};

export type CommonAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchRegisterTermsAndConditionsContentSagaAction;
