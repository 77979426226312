import type {
  FetchDiscoveryProgramsResponseParams,
  FetchProgramsPayload,
  FetchProgramsResponseParams,
  FetchDiscoveryProgramsPayload,
  FetchProgramResponseParams,
  FetchProgramPayload,
} from "@/utils/apis/program";

// Action Types

export enum ProgramActionTypes {
  FETCH_REQUESTED = "@@program/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@program/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@program/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@program/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@program/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@program/MAP_FETCH_FAILED",

  // Saga
  FETCH_PROGRAMS_SAGA = "@@program/FETCH_PROGRAMS_SAGA",
  FETCH_PROGRAM_SAGA = "@@program/FETCH_PROGRAM_SAGA",
  FETCH_DISCOVERY_PROGRAMS_SAGA = "@@program/FETCH_DISCOVERY_PROGRAMS_SAGA",
}

// State

export interface ProgramState {
  programs: FetchProgramsResponseParams["items"];
  programsLoading: boolean;
  programsError: string;
  programsCount: number;

  idToProgramMap: {
    [id: string | number]: FetchProgramResponseParams;
  };
  idToProgramLoadingMap: {
    [id: string | number]: boolean;
  };
  idToProgramErrorMap: {
    [id: string | number]: string;
  };

  discoveryPrograms: FetchDiscoveryProgramsResponseParams;
  discoveryProgramsLoading: boolean;
  discoveryProgramsError: string;
}

// ---- Reducer ----

type FetchScope = "programs" | "discoveryPrograms";
type MapFetchScope = "idToProgram";

export type FetchRequestedAction = {
  type: ProgramActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
  };
};

export type FetchSucceededAction = {
  type: ProgramActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ProgramState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ProgramActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MapFetchRequestedAction = {
  type: ProgramActionTypes.MAP_FETCH_REQUESTED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
  };
};

export type MapFetchSucceededAction = {
  type: ProgramActionTypes.MAP_FETCH_SUCCEEDED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    data: ProgramState[`${MapFetchScope}Map`][MapFetchSucceededAction["payload"]["key"]];
  };
};

export type MapFetchFailedAction = {
  type: ProgramActionTypes.MAP_FETCH_FAILED;
  payload: {
    scope: MapFetchScope;
    key: string | number;
    error: string;
  };
};

// ---- Saga ----

export type FetchProgramsSagaAction = {
  type: ProgramActionTypes.FETCH_PROGRAMS_SAGA;
  payload?: FetchProgramsPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchProgramSagaAction = {
  type: ProgramActionTypes.FETCH_PROGRAM_SAGA;
  payload: FetchProgramPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchDiscoveryProgramsSagaAction = {
  type: ProgramActionTypes.FETCH_DISCOVERY_PROGRAMS_SAGA;
  payload?: FetchDiscoveryProgramsPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type ProgramAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  //
  | FetchProgramsSagaAction
  | FetchProgramSagaAction
  | FetchDiscoveryProgramsSagaAction;
