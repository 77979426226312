import type {
  FetchClassLessonSchedulePayload,
  FetchClassLessonScheduleStartDateResponseParams,
  FetchClassLessonScheduleEndDateResponseParams,
  FetchClassLessonScheduleResponseParams,
  FetchClassLessonScheduleStartDatePayload,
  FetchClassLessonScheduleEndDatePayload,
} from "@/utils/apis/classLessonSchedule/classLessonSchedule.api.types";

// Action Types

export enum ClassLessonScheduleActionTypes {
  FETCH_REQUESTED = "@@classLessonSchedule/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@classLessonSchedule/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@classLessonSchedule/FETCH_FAILED",

  // Saga
  FETCH_CLASS_LESSON_SCHEDULE_SAGA = "@@classLessonSchedule/FETCH_CLASS_LESSON_SCHEDULE_SAGA",
  FETCH_CLASS_LESSON_SCHEDULE_START_DATE_SAGA = "@@classLessonSchedule/FETCH_CLASS_LESSON_SCHEDULE_START_DATE_SAGA",
  FETCH_CLASS_LESSON_SCHEDULE_END_DATE_SAGA = "@@classLessonSchedule/FETCH_CLASS_LESSON_SCHEDULE_END_DATE_SAGA",
}

// State

export interface ClassLessonScheduleState {
  classLessonSchedule: FetchClassLessonScheduleResponseParams;
  classLessonScheduleLoading: boolean;
  classLessonScheduleError: string;

  classLessonScheduleStartDate: FetchClassLessonScheduleStartDateResponseParams;
  classLessonScheduleStartDateLoading: boolean;
  classLessonScheduleStartDateError: string;

  classLessonScheduleEndDate: FetchClassLessonScheduleEndDateResponseParams;
  classLessonScheduleEndDateLoading: boolean;
  classLessonScheduleEndDateError: string;
}

// ---- Reducer ----

export type FetchScope =
  | "classLessonSchedule"
  | "classLessonScheduleStartDate"
  | "classLessonScheduleEndDate";

export type FetchRequestedAction = {
  type: ClassLessonScheduleActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    data?: ClassLessonScheduleState[FetchScope];
  };
};

export type FetchSucceededAction = {
  type: ClassLessonScheduleActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ClassLessonScheduleState[FetchScope];
  };
};

export type FetchFailedAction = {
  type: ClassLessonScheduleActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga ----

export type FetchClassLessonScheduleSagaAction = {
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_SAGA;
  payload: FetchClassLessonSchedulePayload;
  meta?: {
    resolve?: Function;
  };
};

export type FetchClassLessonScheduleStartDateSagaAction = {
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_START_DATE_SAGA;
  payload: FetchClassLessonScheduleStartDatePayload;
  meta?: {
    resolve?: Function;
  };
};

export type FetchClassLessonScheduleEndDateSagaAction = {
  type: ClassLessonScheduleActionTypes.FETCH_CLASS_LESSON_SCHEDULE_END_DATE_SAGA;
  payload: FetchClassLessonScheduleEndDatePayload;
  meta?: {
    resolve?: Function;
  };
};

export type ClassLessonScheduleAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchClassLessonScheduleSagaAction
  | FetchClassLessonScheduleStartDateSagaAction
  | FetchClassLessonScheduleEndDateSagaAction;
