import commonAxios from "@/utils/axios/common.axios";

import { authAxios, authV2Axios, AxiosResponseData } from "@/utils/axios";
import type {
  FetchClassesPayload,
  FetchStaffClassesPayload,
  FetchClassPayload,
  FetchStaffClassPayload,
  FetchSameTypeClassesPayload,
  FetchKidClassPayload,
  UpdateStaffClassKidAttendancePayload,
} from "./class.api.types";

const classesApi = {
  fetchClasses: (payload: FetchClassesPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "cidcClasses/getClassesByClassTypes.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchStaffClasses: (payload: FetchStaffClassesPayload) => {
    return authAxios.get<AxiosResponseData>(
      "CidcClasses/getClassesByStaff.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchStaffClass: (payload: FetchStaffClassPayload) => {
    return authAxios.get<AxiosResponseData>(
      "StudentRegisterClasses/getListStudentByClass.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
        headers: payload.headers,
      }
    );
  },
  fetchClass: (payload: FetchClassPayload) => {
    return commonAxios.get<AxiosResponseData>(
      "cidcClasses/getDetailClassById.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchKidClass: (payload: FetchKidClassPayload) => {
    return authV2Axios.get<AxiosResponseData>(
      "StudentRegisterClasses/fetchRegisterClassByKid.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  fetchSameTypeClasses: (payload: FetchSameTypeClassesPayload) => {
    return authAxios.get<AxiosResponseData>(
      "CidcClasses/webClassesByFromClassId.json",
      {
        params: payload.params,
        cancelToken: payload.cancelToken,
      }
    );
  },
  updateStaffClassKidAttendance: (
    payload: UpdateStaffClassKidAttendancePayload
  ) => {
    return authAxios.post<AxiosResponseData>(
      "CidcClasses/updateStatusAttended.json",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      }
    );
  },
};

export default classesApi;
