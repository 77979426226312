import { all, fork } from "redux-saga/effects";

import auth from "./auth/sagas";
import feedback from "./feedback/sagas";
import program from "./program/sagas";
import course from "./course/sagas";
import classType from "./classType/sagas";
import generalInfo from "./generalInfo/sagas";
import _new from "./new/sagas";
import _class from "./class/sagas";
import centre from "./centre/sagas";
import kid from "./kid/sagas";
import classAlbum from "./classAlbum/sagas";
import classRegistrationOrder from "./classRegistrationOrder/sagas";
import professional from "./professional/sagas";
import notification from "./notification/sagas";
import classLessonSchedule from "./classLessonSchedule/sagas";
import common from "./common/sagas";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(feedback),
    fork(program),
    fork(course),
    fork(classType),
    fork(generalInfo),
    fork(_new),
    fork(_class),
    fork(centre),
    fork(kid),
    fork(classRegistrationOrder),
    fork(classAlbum),
    fork(professional),
    fork(notification),
    fork(classLessonSchedule),
    fork(common),
  ]);
}
